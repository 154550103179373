import { Component, OnInit,ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke
} from "ng-apexcharts";
import { DashboardService } from '../dashboard.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

const monthName = {
  1 : "January",
  2 : "February",
  3 : "March",
  4 : "April",
  5 : "May",
  6 : "June",
  7 : "July",
  8 : "August",
  9 : "September ",
  10 : "October",
  11 : "November",
  12 : "December"
}

@Component({
  selector: 'app-transaction-all-chart',
  templateUrl: './transaction-all-chart.component.html',
  styleUrls: ['./transaction-all-chart.component.scss']
})
export class TransactionAllChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  yearMonthValues : Array<Number> = [];
  yearMonthCategory : Array<String> = [];
  

  constructor(
    private dashboardService: DashboardService
  ) {
    //this.showAggregationYearMonth();
  }

  genrateChart(xaxisValues, categories){
    this.chartOptions = {
    series: [
        {
          name: "count",
          data: xaxisValues
        }
      ],
      chart: {
        height: 350,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        type: "category",
        categories: categories
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm"
        }
      }
    };

  }

  showAggregationYearMonth(){
    this.dashboardService.getAggregationYearMonth().subscribe(aggregation => {
      aggregation.sort((a, b) => (a.year_rec < b.year_rec) ? 1 : -1);
      aggregation.sort((a, b) => (a.year_rec === b.year_rec) ? -1:((a.month_rec > b.month_rec) ? -1 : 1));
      for(let aggr of aggregation){
        this.yearMonthValues.push(aggr.count);
        this.yearMonthCategory.push(monthName[aggr.month_rec]+'-'+aggr.year_rec);
      }
      this.genrateChart(this.yearMonthValues,this.yearMonthCategory);
    },
      (error) => {
       console.log(error)
      });
  }


  ngOnInit(): void {
    this.showAggregationYearMonth();
  }

}
