import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VerificationsService } from '../verifications.service';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from "@angular/forms";
import { AlertService } from '../../shared/_alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { countryJSON } from '../../../assets/files/country'; 
import { ConfigurationService } from 'src/app/configuration/configuration.service';


@Component({
  selector: 'app-verifications-edit',
  templateUrl: './verifications-edit.component.html',
  styleUrls: ['./verifications-edit.component.scss'],
})
export class VerificationsEditComponent implements OnInit {
  countryList: any = countryJSON;
  pipe = new DatePipe('en-US');

  verificationGroup: FormGroup;
  mobileNumber: String;
  personName: String;
  country: String = "United States";
  countryCode: String = "US";
  requestId: String;
  dateOfBirth: Date;
  callbackURL: string;
  redirectURL: string;
  redirectBackURLMatched = true;
  callBackURLMatched = true;
  isLiveVideo = false;
  // country = "US";
  id: number;
  identifier: number;
  editMode = false;
  status: String;
  pageTitleLabel: String = 'New Verification';

  // Tenant's configurations
  doBVerification: Boolean;
  skipLiveness: Boolean;
  forgeryDetection: Boolean;
  showScannedImages: Boolean;
  hideFooter: Boolean;
  enableCountryList: Boolean;
  enableLiveVideo: Boolean;
  skipInputName: Boolean;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private verificationsService: VerificationsService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.verificationGroup = new FormGroup({
      identifier: new FormControl(''),
      mobileNumber: new FormControl(''),
      personName: new FormControl(''),
      country: new FormControl(),
      requestId: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl(''),
      callbackURL: new FormControl(''),
      redirectURL: new FormControl(''),
      isLiveVideo: new FormControl(''),
    });
    
    this.setcountryNameCode();
    this.route.params
      .subscribe((params: Params) => {
        this.id = params['id'];
        this.editMode = params['id'] != null;
        this.getTenantConfiguration();
        if (this.editMode) {
          this.pageTitleLabel = "Update Verification";
          this.initForm();
        }
      });
  }

  getTenantConfiguration() {
    const api = this.configurationService.getConfiguration();
    api.subscribe(
      (tenantDetail) => {
        this.doBVerification = tenantDetail.doBVerification;
        this.skipLiveness = tenantDetail.skipLiveness;
        this.forgeryDetection = tenantDetail.forgeryDetection;
        this.showScannedImages = tenantDetail.showScannedImages || null;
        this.hideFooter = tenantDetail.hideFooter || null;
        this.enableCountryList = tenantDetail.enableCountryList || null;
        this.enableLiveVideo = tenantDetail.enableLiveVideo;
        this.skipInputName = tenantDetail.skipInputName;
        
        if (!tenantDetail.skipInputName) {
          this.verificationGroup.controls['personName'].setValidators([
            Validators.required,
          ]);
          this.verificationGroup.controls[
            'personName'
          ].updateValueAndValidity();
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  private initForm() {
    if (this.editMode) {
      this.verificationGroup.controls['identifier'].disable();
      this.verificationGroup.controls['mobileNumber'].disable();
      this.verificationGroup.controls['personName'].disable();
      this.verificationGroup.controls['country'].disable();
      this.verificationGroup.controls['requestId'].disable();
      this.verificationGroup.controls['callbackURL'].disable();
      this.verificationGroup.controls['dateOfBirth'].disable();
      this.verificationGroup.controls['isLiveVideo'].disable();

      this.spinner.show();
      this.verificationsService
        .getVerificationsById(this.id)
        .subscribe((verification) => {
          // console.info("Verification ==>" + JSON.stringify(verification));
          this.identifier = verification.identifier;
          this.mobileNumber = verification.mobile;
          this.personName = verification.name;
          this.country = verification.country;
          this.countryCode = verification.countryCode;
          this.requestId = verification.requestID;
          this.dateOfBirth = verification.dateOfBirth;
          this.callbackURL = verification.callbackURL;
          this.redirectURL = verification.redirectURL;
          this.isLiveVideo = verification.isLiveVideo;
          this.spinner.hide();
        });
    }
  }

  setcountryNameCode() {
    for(let result of this.countryList){
      if(this.country === result.name){
        this.countryCode = result.code;
        break;
      }
    }
  }

  onCallbackURL(event: any) {
    this.callbackURL = event.target.value;
    if (
      this.callbackURL.startsWith('https://') ||
      this.callbackURL.startsWith('http://') ||
      !this.callbackURL
    ) {
      this.callBackURLMatched = true;
    } else {
      this.callBackURLMatched = false;
    }
  }

  onRedirectURL(event: any) {
    this.redirectURL = event.target.value;
    if (
      this.redirectURL.startsWith('https://') ||
      this.redirectURL.startsWith('http://') ||
      !this.redirectURL
    ) {
      this.redirectBackURLMatched = true;
    } else {
      this.redirectBackURLMatched = false;
    }
  }

  onBack() {
    this.router.navigate(['verifications']);
  }

  onSave() {
    this.spinner.show();

    if (this.editMode) {
      const dobFormatted = this.pipe.transform(
        this.verificationGroup.get('dateOfBirth').value,
        'yyyyMMdd'
      );
      const verification = {
        "mobile": this.verificationGroup.get('mobileNumber').value,//this.mobileNumber
        "name": this.verificationGroup.get('personName').value ?? '', //this.personName,
        "country": this.verificationGroup.get('country').value,//this.country,
        "countryCode": this.countryCode,//this.countryCode,        
        "requestID": this.verificationGroup.get('requestId').value,//this.requestId,
        "dateOfBirth": dobFormatted,
        "callbackURL": this.verificationGroup.get('callbackURL').value,//this.callbackURL
        "redirectURL": this.verificationGroup.get('redirectURL').value,
        "liveVideo":this.verificationGroup.get('isLiveVideo').value,
      };

      this.verificationsService
        .updateVerification(this.id, verification)
        .subscribe(
          (result) => {
            this.alertService.success(
              'Verification detail updated Successfully.',
              this.options
            );
            this.spinner.hide();
            this.onBack();
          },
          (e) => {
            this.spinner.hide();
            this.alertService.error(e.error.errorMessage, this.options);
          }
        );
    } else {
      const dobFormatted = this.pipe.transform(
        this.verificationGroup.get('dateOfBirth').value,
        'yyyyMMdd'
      );

      const verification = {
        "mobile": this.verificationGroup.get('mobileNumber').value,//this.mobileNumber
        "name": this.verificationGroup.get('personName').value ?? '', //this.personName,
        "country": this.verificationGroup.get('country').value,//this.country,
        "countryCode": this.countryCode, //this.countryCode, 
        "requestID": this.verificationGroup.get('requestId').value,//this.requestId,
        "dateOfBirth": dobFormatted,
        "callbackURL": this.verificationGroup.get('callbackURL').value,//this.callbackURL
        "redirectURL": this.verificationGroup.get('redirectURL').value,
        "liveVideo":this.verificationGroup.get('isLiveVideo').value,
      };

      this.verificationsService.createVerification(verification).subscribe(
        (result) => {
          this.alertService.success(
            'Verification detail added Successfully.',
            this.options
          );
          this.spinner.hide();
          this.onBack();
        },
        (e) => {
          this.spinner.hide();
          this.alertService.error(e.error.errorMessage, this.options);
        }
      );
    }
  }
}
