import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MetaService } from '../meta.service';
import { FormGroup } from '@angular/forms';
import { AlertService } from '../../shared/_alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { countryJSON } from '../../../assets/files/country';

@Component({
  selector: 'app-metadata-edit',
  templateUrl: './metadata-edit.component.html',
  styleUrls: ['./metadata-edit.component.scss']
})
export class MetadataEditComponent implements OnInit {

  countryList: any = countryJSON;
  pipe = new DatePipe('en-US');

  id: number;
  form: FormGroup;
  country: String;
  countryCode: String;
  barcode: String;
  dateFormat: String;
  type: String;
  alternateText: String;
  live = false;
  editMode = false;
  engineLanguage = false;
  countryEuropean= false;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        this.id = params['id'];
        this.editMode = params['id'] != null;
        this.initForm(this.id);
      });
  }

  onBack() {
    this.router.navigate(['metadata']);
  }

  private initForm(id) {

    if (this.editMode) {
      this.spinner.show();
      this.metaService.getEntries()
        .subscribe(entries => {
          const entry = entries.filter(function (entry) {
            return entry.id == id;
          });
          console.log(entry[0]);
          this.country = entry[0].country;
          this.countryCode = entry[0].countryCode;
          this.type = entry[0].type;
          this.barcode = entry[0].barcode;
          this.dateFormat = entry[0].dateFormat;
          this.alternateText = entry[0].alternateText;
          this.live = entry[0].live;
          this.engineLanguage = entry[0].engineLanguage;
          this.countryEuropean = entry[0].countryEuropean; 
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
            console.log(error)
          });
    }
  }

  onSave() {
    this.spinner.show();
    if (!this.editMode) {
      const entry = {
        "country": this.country,
        "countryCode": this.countryCode,
        "type": this.type,
        "dateFormat": this.dateFormat,
        "barcode": this.barcode,
        "alternateText": this.alternateText,
        "live": this.live,
        "engineLanguage": this.engineLanguage,
        "countryEuropean": this.countryEuropean
      };
      this.metaService.createMetaData(entry).subscribe((result) => {
        this.alertService.success("Metadata added sucessfully.", this.options)
        this.spinner.hide();
        this.onBack()
      }, (e) => {
        this.spinner.hide();
        this.alertService.error(e.error.errorMessage, this.options)
      });;

    } else {
      const editEntry = {
        "id": this.id,
        "country": this.country,
        "countryCode": this.countryCode,
        "type": this.type,
        "dateFormat": this.dateFormat,
        "barcode": this.barcode,
        "alternateText": this.alternateText,
        "live": this.live,
        "engineLanguage": this.engineLanguage,
        "countryEuropean": this.countryEuropean
      };
      this.metaService.editMeataData(editEntry).subscribe((result) => {
        this.alertService.success("Metadata has changed sucessfully.", this.options)
        this.spinner.hide();
        this.onBack()
      }, (e) => {
        this.spinner.hide();
        this.alertService.error(e.error.errorMessage, this.options)
      });
    }

  }


}
