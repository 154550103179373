import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '../meta.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../shared/_alert/alert.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { StatusLabelComponent } from '../status-label/status-label.component';
import { environment } from '../../../environments/environment';
import { EngineLabelComponent } from '../engine-label/engine-label.component';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-metadata-list',
  templateUrl: './metadata-list.component.html',
  styleUrls: ['./metadata-list.component.scss']
})
export class MetadataListComponent implements OnInit {

  source: LocalDataSource = new LocalDataSource();
  private selectedRow: any;

  baseUrl = environment.baseUrl;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private metaService: MetaService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) { }


  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      country: {
        title: 'Country',
        type: 'String',
        width: '25%',
        filter: true
      },
      countryCode: {
        title: 'Country Code',
        type: 'String',
        width: '10%',
        filter: true
      },
      type: {
        title: 'Type',
        type: 'String',
        width: '10%',
        filter: true
      },
      barcode: {
        title: 'Barcode',
        type: 'String',
        width: '15%',
        filter: true
      },
      dateFormat: {
        title: 'Date Format',
        type: 'String',
        width: '15%',
        filter: false
      },
      live: {
        title: 'Status',
        type: 'custom',
        renderComponent: StatusLabelComponent,
        width: '10%',
        class: 'text-align: center',
        filter: false
      },
      engineLanguage: {
        title: 'Engine Language',
        type: 'custom',
        renderComponent: EngineLabelComponent,
        width: '12%',
        class: 'text-align: center',
        filter: false
      },
    },
  };


  ngOnInit(): void {
    this.tableSettings = Object.assign({}, this.tableSettings);
    this.loadMetaDataList();
  }

  onCreate(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  onUpload(): void {
    this.router.navigate(['upload'], { relativeTo: this.route });
  }

  onEdit(event): void {
    this.router.navigate([event.data.id, 'edit'], { relativeTo: this.route });
  }

  onUserRowSelect(event): void {
    // this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  /* downloadfile() {
     let thefile = [];
     this.metaService.downloadMetadataCSV()
       .subscribe(data => { thefile.push(data); console.log(data) },
         error => console.log("Error downloading the file."),
         () => {
           let url = window.URL.createObjectURL(new Blob(thefile, { type: "application/octet-stream" }));
           var a = document.createElement("a");
           document.body.appendChild(a);
           a.href = url;
           a.download = 'metadata.csv';
           a.target = '_blank';
           a.click();
         });
   }*/

  downloadfile() {
    const pdfUrl = `${this.baseUrl}/metadata/download`;
    const pdfName = 'metadata_csv';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  private loadMetaDataList() {
    this.spinner.show();
    this.metaService.getEntries()
      .subscribe(entries => {
        // console.log(entries)
        this.source.load(entries);
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          console.log(error)
        });
  }

  showStaticModal(rowData: any) {

    this.selectedRow = rowData;
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = "Delete User";
    modalRef.componentInstance.message = "Are you sure you want to delete this user?";
    modalRef.componentInstance.btnOkText = "Ok";
    modalRef.componentInstance.btnCancelText = "Cancel";

    modalRef.result.then((result) => {
      if (result) {
        this.metaService.deleteEntry(this.selectedRow.data.id)
          .subscribe(
            result => {
              this.alertService.success("Metadata has been deleted Sucessfully", this.options)
              this.loadMetaDataList();
            },
            error => {
              console.log(error);
              this.alertService.error("Metadata deleted failed", this.options)
            }
          );
      } else {
        modalRef.close();
      }
    });
  }


}
