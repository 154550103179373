import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../shared/_alert/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentService } from '../agent.service';
import { StatusLabelComponent} from '../status-label/status-label.component';
import { VideoVerificationComponent } from '../video-verification/video-verification.component';


@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit {
  public loading = false;
  private selectedRow: any;
  closeResult: string;
  pageOffset = 0;
  dataLimit = 15;
  disablePrevButton = true;
  disableNxtButton = false;
  dataSize = 0;
  status = 'pending';
  interval;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  statusList: any = {
    All: '',
    Pending: 'pending',
    'In Progress': 'in_progress',
    Completed: 'completed',
    Expired: 'timed_out'
  };

  source: LocalDataSource = new LocalDataSource();
  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      columnTitle: 'Video',
      width: '15%',
      editUrl: true, 
      custom: [
        {
          name: 'view',
          title:
            '<span><i class="fas fa-eye" style="font-size:48px;color:red" style="font-size:20px;"></i></span></br>',
        },
      ],
    },
    pager: {
      display: true,
      perPage: 15,
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      identifier: {
        title: 'Identifier',
        type: 'String',
        width: '20%',
        filter: false,
      },
      username: {
        title: 'Username',
        type: 'String',
        width: '20%',
        filter: false,
      },
      agentId: {
        title: 'Agent Id',
        type: 'String',
        width: '20%',
        filter: false,
      },
      agentName: {
        title: 'Agent Name',
        type: 'String',
        width: '20%',
        filter: false,
      },
      status: {
        title: 'Status',
        type: 'custom',
        renderComponent: StatusLabelComponent,
        width: '20%',
        filter: false,
      },
    },
  };

  constructor(
    private agentService: AgentService,
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.tableSettings = Object.assign({}, this.tableSettings);
    this.loadVerifyQueue(this.pageOffset, this.dataLimit, this.status);

    this.interval = setInterval(()=> {
      this.loadVerifyQueueInBackground(this.pageOffset, this.dataLimit, this.status);
    }, 5000);
  }

  onBack() {
    if (this.pageOffset > 0) {
      this.pageOffset = this.pageOffset - 1;
      this.loadVerifyQueue(this.pageOffset, this.dataLimit, this.status);
    } else {
      this.disablePrevButton = true;
    }
  }

  onUserRowSelect(event): void {
    // this.router.navigate([event.data.identifier, 'view'], {
    //   relativeTo: this.route,
    //   queryParams: { queueId: event.data.id },
    // }); 
  }

  onChangeSelect(event) {
    this.pageOffset = 0;
    this.loadVerifyQueue(this.pageOffset, this.dataLimit, this.status);
  }

  onNext() {
    this.pageOffset = this.pageOffset + 1;
    this.loadVerifyQueue(this.pageOffset, this.dataLimit, this.status);
  }

  /* onView(event): void{
    this.router.navigate(['/verifications/',event.data.identifier,'/view'], { relativeTo: this.route });
  }*/

  onEdit(event): void {
    this.router.navigate([event.data.id, 'edit'], { relativeTo: this.route });
  }

  onView(event): void {
    let actionName = `${event.action}`;
    let status = `${ event.data.status}`;
   
    if (actionName === 'view' && status != 'completed' && status != 'timed_out') {
      // this.router.navigate([event.data.identifier, 'view'], {
      //   relativeTo: this.route,
      //   queryParams: { queueId: event.data.id, status: event.data.status},
      // });

      this.openModal(event);
    }
  }

  private loadVerifyQueue(pageOffset, limit, status) {
    this.spinner.show();
    this.source.load([]);
    this.disableNxtButton = true;
    this.agentService.getVerifyQueue(pageOffset, limit, status).subscribe(
      (list) => {
        this.dataSize = list.length;
        if (list.length > 0) {
          this.source.load(list);
          this.disableNxtButton = false;
          this.disablePrevButton = false;
        } else {
          this.disableNxtButton = true;
          this.disablePrevButton = false;
        }
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  private loadVerifyQueueInBackground(pageOffset, limit, status) {

    //background refresh
    if (status !== 'pending') {
      return;
    }
    this.source.load([]);
    this.agentService.getVerifyQueue(pageOffset, limit, status).subscribe(
      (list) => {
        this.dataSize = list.length;
        if (list.length > 0) {
          this.source.load(list);
        } 
      },
      (error) => {
        console.log(error);
      }
    );
  }
 

  openModal(event) { 
    const modalRef = this.modalService.open(VideoVerificationComponent,{ size: 'xl',keyboard : false , backdrop : 'static'});
    modalRef.componentInstance.event = event;  
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  } 
}
