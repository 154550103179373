<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Analytics</h1>
</div>

<br>
<br>
<br>
<br>
<br>


<nb-card>

  <nb-card-body>

    <div class="container-fluid">
      <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
        [fullScreen]="true" size="medium">
        <p class="loading">Loading....</p>
      </ngx-spinner>
      <section class="col-lg-12">
        <div class="card-body">
          <div class="transactionBar">

            <div class="transactionItem">
              <div class="info">
                <p id="transactionNum">&nbsp;&nbsp;{{totalTransactions}}</p>
                <br>
                <p id="transactionName">&nbsp;&nbsp;Total Transactions</p>
              </div>
              <div class="icon">
                <i class="fas fa-globe blue"></i>
              </div>
            </div>

            <div class="transactionItem">
              <div class="info">
                <p id="transactionNum">&nbsp;&nbsp;{{successTransactions}}</p>
                <br>
                <p id="transactionName">&nbsp;&nbsp;Success Transactions</p>
              </div>
              <div class="icon">
                <i class="fas fa-check-circle green"></i>
              </div>
            </div>

            <div class="transactionItem">
              <div class="info">
                <p id="transactionNum">&nbsp;&nbsp;{{inProgressTransactions}}</p>
                <br>
                <p id="transactionName">&nbsp;&nbsp;Pending Transactions</p>
              </div>
              <div class="icon">
                <i class="fas fa-redo-alt"></i>
              </div>
            </div>



          </div>

        </div>
      </section>

      <section class="col-lg-12">
        <div class="card-body">
          <div class="transactionBar">
            <div class="transactionItem">
              <div class="info">
                <p id="transactionNum">&nbsp;&nbsp;{{startedTransactions}}</p>
                <br>
                <p id="transactionName">&nbsp;&nbsp;Started Transactions</p>
              </div>
              <div class="icon">
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
            <div class="transactionItem">
              <div class="info">
                <p id="transactionNum">&nbsp;&nbsp;{{errorTransactions}}</p>
                <br>
                <p id="transactionName">&nbsp;&nbsp;Error Transactions</p>
              </div>
              <div class="icon">
                <i class="fas fa-exclamation-triangle"></i>
              </div>
            </div>
            <div class="transactionItem">
              <div class="info">
                <p id="transactionNum">&nbsp;&nbsp;{{failedTransactions}}</p>
                <br>
                <p id="transactionName">&nbsp;&nbsp;Failed Transactions</p>
              </div>
              <div class="icon">
                <i class="far fa-times-circle red"></i>
              </div>
            </div>

          </div>
        </div>
      </section>

      <br>



      <section class="col-lg-12">
        <div class="card-body">
          <div class="chartBar">
            <div class="chartItem">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <i class="fas fa-chart-pie mr-1"></i>
                    &nbsp;&nbsp; Transactions (Last 12 months)
                  </h3>

                </div>
                <div class="card-body">
                  <app-transaction-all-chart></app-transaction-all-chart>
                </div>
              </div>
            </div>

            <div class="chartItem">
              <div class="card bg-gradient-info">
                <div class="card-header">
                  <h3 class="card-title">
                    <i class="fas fa-envelope mr-1"></i>
                    &nbsp;&nbsp; Transactions (Last 12 days)
                  </h3>
                </div>
                <div class="card-body">
                  <app-transaction-sms-chart> </app-transaction-sms-chart>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section class="col-lg-12">
        <div class="card-body">
          <ng2-smart-table [settings]="tableSettings" [source]="source"></ng2-smart-table>
        </div>
      </section>




    </div>
  </nb-card-body>
</nb-card>