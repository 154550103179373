import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { NbIconModule } from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import {PrivacyComponent} from './privacy/privacy.component';
import {ProfileComponent} from './profile.component';
import {AlertModule} from '../shared/_alert/alert.module';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
 


@NgModule({
  declarations: [
    PrivacyComponent,
    ProfileComponent,
    PasswordStrengthBarComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NbToggleModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NbIconModule,
    AlertModule
  ]
})
export class ProfileModule { }
