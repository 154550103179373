import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from '../app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NbToggleModule,NbCardModule} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { NbIconModule, NbSelectModule} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table'; 
import { ReportComponent } from './report.component';
import { VerificationDetailsReportComponent } from './verification-details-report/verification-details-report.component';




@NgModule({
  declarations: [ReportComponent,VerificationDetailsReportComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NbToggleModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgSelectModule,
    NbSelectModule,
    NbIconModule,
    Ng2SmartTableModule,
    NgbModule
  ]
})
export class ReportsModule { }
