import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '../profile.service';
import { AlertService } from '../../shared/_alert/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  email: String;
  user: any;
  currentPassword: String;
  newPassword: String;
  reTypePassword: String;
  public barLabel: string = "Password strength:";
  form: FormGroup;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    public alertService: AlertService
  ) { }

  ngOnInit(): void {
    const user = this.profileService.getUserDetail();
    this.email = user.email;
  }

  onSave() {
    let result = this.checkStrength(this.newPassword);
    let test = this.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?#^*~&])(?=.*[a-zA-Z]).{8,}$/);
    if (test !== null && result === 40) {
      if (this.newPassword && (this.newPassword === this.reTypePassword)) {
        this.profileService.changePassword(this.email, this.currentPassword, this.newPassword).subscribe((result) => {
          this.alertService.success("Your Password has changed successfully", this.options)
          this.clearForm();
          this.onBack();
        }, (e) => {
          this.alertService.error(e.error.errorMessage, this.options)
        });
      } else {
        this.alertService.error("Password not matched", this.options)
      }
    } else {
      this.alertService.error("The password must contain at least 8 character categories among the following:" +
        "<br> Uppercase characters (A-Z),<br> Lowercase characters (a-z),<br> Numbers (0-9), " +
        "<br> Special characters (~! @#$%^&*_-+=`|\(){}[]:;\"'<>,.?/)", this.options);
    }
  }

  onBack() {
    this.router.navigate(['../dashboard'], { relativeTo: this.route });
  }

  clearForm() {
    this.currentPassword = null;
    this.newPassword = null;
    this.reTypePassword = null;
  }


  enableChangePassword() {
    if (this.currentPassword && this.newPassword && (this.newPassword === this.reTypePassword)) {
      return false;
    }
    return true;
  }




  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@#`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    return force;
  }
}
