
import { NgModule, Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent implements OnInit {
  @Input() status;


  @Output() statusChanged = new EventEmitter();

  marked = '';

  inProgress = 'Pending'
  success = 'Success'
  failed = 'Failed'
  started = 'Started'
  error = 'Error'
  expired='Expired'

  displayValue = ''


  constructor() { }

  ngOnInit() {
    this.changeValue(this.status);

  }


  changeValue(status) {

    if (status === 'verified') {
      this.displayValue = this.success;
      return this.styles.success;
    } else if (status === 'failed') {
      this.displayValue = this.failed;
      return this.styles.failed;
    } else if (status === 'started') {
      this.displayValue = this.started;
      return this.styles.started;
    } else if (status === 'error') {
      this.displayValue = this.error;
      return this.styles.error;
    }else if(status === 'timed_out'){
      this.displayValue = this.expired;
      return this.styles.expired;
    } else {
      this.displayValue = this.inProgress;
      return this.styles.pending;
    }

  }


  styles = {
    pending: {
      'background-color': '#ffc107',
      'color': 'gray'
    },
    success: {
      'background-color': '#28a745',
      'color': 'white'
    },
    failed: {
      'background-color': '#dc3545',
      'color': 'white'
    },
    started: {
      'background-color': '#2a70bf',
      'color': 'white'
    },
    error: {
      'background-color': '#b81220',
      'color': 'white'
    },
    expired:{
      'background-color': '#807d7d',
      'color': 'white'
    }
  }


}
