import { Component, OnInit,ViewChild  } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";
import { DashboardService } from '../dashboard.service';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-transaction-sms-chart',
  templateUrl: './transaction-sms-chart.component.html',
  styleUrls: ['./transaction-sms-chart.component.scss']
})
export class TransactionSmsChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  yearMonthValues : Array<Number> = [];
  yearMonthCategory : Array<String> = [];

  constructor(
    private dashboardService: DashboardService
  ) {
  }

  genrateChart(xaxisValues, categories){
  this.chartOptions = {
    series: [
      {
        name: "count",
        data: xaxisValues
      }
    ],
    chart: {
      height: 350,
      type: "area"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth"
    },
    title: {
      text: "",
      align: "left"
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories:categories
    }
  };
}

showAggregationDaily(){
  this.dashboardService.getAggregationDaily().subscribe(aggregation => {
    aggregation.sort((a, b) => (a.date > b.date) ? 1 :  -1 )

    for(let aggr of aggregation){
      this.yearMonthValues.push(aggr.total);
      this.yearMonthCategory.push(aggr.date);
    }
    this.genrateChart(this.yearMonthValues,this.yearMonthCategory);
  },
    (error) => {
      console.log(error)
    });
}


  ngOnInit(): void {
    this.showAggregationDaily();
  }

}
