import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-engine-label',
  templateUrl: './engine-label.component.html',
  styleUrls: ['./engine-label.component.scss']
})
export class EngineLabelComponent implements OnInit {
  @Input() value: boolean | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  renderValue: string;
  displayValue:string;
  buttonStyle={}

  constructor() { }

 ngOnInit() {
    this.changeValue(this.value);
  }

  changeValue(engine) { 
    if (engine) {
      this.displayValue = "GCV";
      this.buttonStyle = this.styles.active;
    } else {
      this.displayValue = "AWS";
      this.buttonStyle = this.styles.inactive;
    }
  }


  styles = {
    active: {
      'background-color': '#28a745',
      'color': 'white'
    },
    inactive: {
      'background-color': '#ffc107',
      'color': 'gray'
    }
  }


}
