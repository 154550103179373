import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from "@nebular/theme";
@Component({
  selector: 'app-home-auth',
  template: '<router-outlet></router-outlet>',
})
export class AuthLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
