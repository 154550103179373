<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Edit/Add Meta Data</h1>
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Enter Metadata Details</h1>
          </div>
        </div>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body>
    <div class="container" id="form">

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="country">Country: </label>
          <input type="text" class="col-md-10 form-control" id="country" placeholder="Country" [(ngModel)]="country">
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="country">Country Code: </label>
          <input type="text" class="col-md-10 form-control" id="countryCode" placeholder="Country Code"
            [(ngModel)]="countryCode">
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="type">Type: </label>
          <input type="text" class="col-md-10 form-control" id="type" placeholder="Type" [(ngModel)]="type">
        </div>
      </div>

      <br>
      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="name">Live: </label>
          <nb-toggle [(ngModel)]="live"></nb-toggle>
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="barcode">Barcode: </label>
          <input type="text" class="col-md-10 form-control" id="barcode" placeholder="Barcode" [(ngModel)]="barcode">
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="dateFormat">Date Format: </label>
          <input type="text" class="col-md-10 form-control" id="dateFormat" placeholder="Date Format"
            [(ngModel)]="dateFormat">
        </div>
      </div>


      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="alternateText">Alternate Text: </label>
          <input type="text" class="col-md-10 form-control" id="alternateText" placeholder="Alternate Text"
            [(ngModel)]="alternateText">
        </div>
      </div>

      <br>
      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="engineLanguage">Engine Language GCV: </label>
          <nb-toggle [(ngModel)]="engineLanguage"></nb-toggle>
        </div>
      </div>

      <br>
      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="countryEuropean">Is Country European: </label>
          <nb-toggle [(ngModel)]="countryEuropean"></nb-toggle>
        </div>
      </div>

      <br>

      <div class="form-group row">
        <div class="col-md-2 col-md-offset-10 input-group">
          <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
            [fullScreen]="true" size="medium">
            <p class="loading">Updating ....</p>
          </ngx-spinner>
          <button type="button" class="btn btn-primary btn-lg" (click)="onSave()">Save</button>

        </div>
      </div>




    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->