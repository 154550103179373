import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from './../../shared/_alert/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  form: FormGroup;
  forgotPwdForm: FormGroup;
  enableForgePassword = true;
  showLogin = false;
  recaptchResponse: string;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
  ) { }


  ngOnInit(): void {

    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });

    this.forgotPwdForm = new FormGroup({
      email: new FormControl()
    });
    this.addRecaptchaScript();
  }

  basicLogin() {
    if (this.recaptchResponse) {
      this.spinner.show();
      this.authService.basicLogin(this.form.value.email, this.form.value.password).subscribe(response => {
        if (response) {
          this.spinner.hide();
        }
      }, (e) => {
        this.spinner.hide();
        if (e.error.status === 401) {
          this.alertService.error(e.error.errorMessage, this.options)
        } else if (e.error.status === 423) {
          this.alertService.error(e.error.errorMessage, this.options)
          this.forgetPassword();
        } else if (e.error.status === 404) {
          this.alertService.error(e.error.errorMessage, this.options)
        } else {
          this.alertService.error("Somethings wrong", this.options)
        }
      })
    } else {
      this.alertService.error("Please complete the captcha.", this.options)
    }

  }

  resetPassword() {
    this.spinner.show();
    if(this.forgotPwdForm.value.email !== null){
      this.authService.resetPassword(this.forgotPwdForm.value.email).subscribe(reseponse => {
        // this.alertService.success("Your new recovery password will be sent to "+ reseponse.email+ " address", this.options)
        this.alertService.success("If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.", this.options);
        this.spinner.hide();
      }, (e) => {
        // this.alertService.error(e.error.errorMessage , this.options);
        this.alertService.success("If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.", this.options);
        this.spinner.hide();
      })
    }else{
      this.spinner.hide();
      this.alertService.error("Please enter valid email.", this.options);
    }
  }

  forgetPassword() {
    this.enableForgePassword = false;
    this.showLogin = true;
  }
  hiddenForgetPassword() {
    this.showLogin = false;
    this.enableForgePassword = true;
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': environment.recaptcha.siteKey,
      'callback': (response) => {
        if (response) {
          this.recaptchResponse = response;
        }
      }
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

}
