<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="row" id="headerBar">
      <h1 id="pageTopic">&nbsp;&nbsp;&nbsp;User Information</h1>

    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <nb-select class="status-select" [(selected)]="status" [(ngModel)]="status"
            (selectedChange)="onChangeSelect($event)" placeholder="Select Status">
            <nb-option *ngFor="let status of statusList | keyvalue" [value]="status.value">{{status.key}}</nb-option>
          </nb-select>
        </div>
      </div>

    </div><!-- /.container-fluid -->
  </div>

</div>

<br>

<nb-card>

  <nb-card-body>
    <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
      [fullScreen]="true" size="medium">
      <p class="loading">Loading....</p>
    </ngx-spinner>
    <ng2-smart-table class="tbl-col dataTable" id='testTable' [settings]="tableSettings" [source]="source"
      (userRowSelect)="onUserRowSelect($event)" (custom)="onView($event)">
    </ng2-smart-table>
    <div class="container-fluid">
      <div class="row justify-content">
        <div class="col-md-offset-9 col-md-1">
          <button type="button" [disabled]='disablePrevButton' (click)="onBack()"
            class="btn btn-primary btn-lg paginate-button">Previous</button>
        </div>
        <div class="col-md-1">
          <button type="button" [disabled]="disableNxtButton || dataSize<15" (click)="onNext()"
            class="btn btn-primary btn-lg paginate-button">Next</button>
        </div>
      </div>

    </div>
  </nb-card-body>
</nb-card>