<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">

      <div class="row" id="headerBar">
        <h1 id="pageTopic">Applications</h1>
      </div>

      <br>
      <br>
      <br>
      <br>
      <br>

      <div class="row mb-2">
        <div class="input-group col-md-3">
          <input #search class="form-control form-control-navbar" id="searchInput" type="search" placeholder="Search"
            aria-label="Search" (keyup)="onSearch(search.value)">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </div>
        <!--div class="input-group offset-md-8 col-md-1">      
                      <button type="button" (click)="onCreate()" class="btn btn-primary btn-md">New Application</button> 
                      </div-->
      </div>
    </div><!-- /.container-fluid -->
  </div>
</div>

<br>

<nb-card>
  <nb-card-body>
    <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
      [fullScreen]="true" size="medium">
      <p class="loading">Loading....</p>
    </ngx-spinner>
    <ng2-smart-table class="tbl-col dataTable" id='testTable' [settings]="tableSettings" [source]="source"
      (editConfirm)="onCopy($event)" (edit)="onShow($event)" (delete)="showStaticModal($event)"
      (userRowSelect)="onUserRowSelect($event)" (custom)="onView($event)">
    </ng2-smart-table>

  </nb-card-body>
</nb-card>