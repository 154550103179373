
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
      <div class="row" id="headerBar">
        <h1 id="pageTopic" >   Meta Information</h1>
        
      </div>

      <br>
      <br>
      <br>
      <br>
      <br>
      <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-2">
            <button type="button" (click)="onCreate()" class="btn btn-primary btn-lg">Create New MetaData</button>
        </div>
        <div class="col-md-2">
          <button type="button" (click)="onUpload()" class="btn btn-primary btn-lg">Upload MetaData</button>
      </div>
      <div class="col-md-2">
        <button type="button" (click)="downloadfile()" class="btn btn-primary btn-lg">Download</button>
    </div>
          </div>
        
      </div><!-- /.container-fluid -->
    </div>
  </div>
</div>

<br>

<nb-card>

    <nb-card-body>
      <ngx-spinner bdColor = "rgb(51, 153, 255,0.1)" size = "medium" color = "#0073e6" type = "square-jelly-box" [fullScreen] = "true" size="medium">
        <p class="loading">Loading....</p>
      </ngx-spinner>
        <ng2-smart-table class="tbl-col dataTable" id='testTable'  [settings]="tableSettings" [source]="source"
        (edit)="onEdit($event)" (delete)="showStaticModal($event)" (userRowSelect)="onUserRowSelect($event)">
        </ng2-smart-table>
    </nb-card-body>
  </nb-card>
