import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, RoutesRecognized } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor( private authSerivce: AuthService,private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const isAuth = this.authSerivce.isUserAuthenticated();

    if (!isAuth) {
      this.router.navigate(['auth', 'login']);
      return false;
    }
    return true;
  }
}
