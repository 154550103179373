import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerificationsService {

  headers: HttpHeaders = new HttpHeaders();

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }


  getVerifications() {
    return this.http.get(this.baseUrl + '/verify')
      .pipe(map((response: any) => {
        return response;
      }));
  }
 
  getVerificationsPerPage(page, limit) {
    return this.http.get(this.baseUrl + '/verify?page=' + page + '&limit=' + limit)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  searchVerificationWithStatus(searchText, status, offset, limit) {
    return this.http.get(this.baseUrl + '/verify/search?offset=' + offset + '&limit=' + limit + '&searchText=' + searchText + '&status=' + status)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  searchVerificationWithTenantIdAndStatus(tenantId, status, searchText, offset, limit) {
    return this.http.get(this.baseUrl + '/verify/search/' + tenantId + '?offset=' + offset + '&limit=' + limit + '&searchText=' + searchText + '&status=' + status)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getVerificationsById(id) {
    return this.http.get(this.baseUrl + '/verify/identifier/' + id)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getVerificationsDetailByTenantId(tenantId, id) {
    return this.http.get(this.baseUrl + '/verify/tenant/' + tenantId + '/identifier/' + id)
      .pipe(map((response: any) => {
        return response;
      }));
  }


  getVerificationsByTenantId(id, page, limit) {
    return this.http.get(this.baseUrl + '/verify/tenant/' + id + '?page=' + page + '&limit=' + limit)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getVerificationsByTenantIdAndStatus(id, status, page, limit) {
    return this.http.get(this.baseUrl + '/verify/tenantWithStatus/' + id + '?status=' + status + '&page=' + page + '&limit=' + limit)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getTenantList() {
    return this.http.get(this.baseUrl + '/tenant')
      .pipe(map((response: any) => {
        return response;
      }));
  }


  createVerification(user) {
    return this.http.post(this.baseUrl + '/verify',
      user)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateVerificationStatus(id, body) {
    return this.http.put(this.baseUrl + '/verify/' + id,
      body)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateVerificationStatusByAgent(id, body){
    return this.http.put(this.baseUrl + '/verify/agent/' + id,
      body)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateVerification(id, body) {
    return this.http.put(this.baseUrl + '/verify/edit/' + id,
      body)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  searchVerificationWithDateRange(fromDate, toDate, status, searchValue, offset, limit) {
    return this.http.get(this.baseUrl + '/verify/searchByDate?offset=' + offset + '&limit=' + limit + '&fromDate=' + fromDate + '&toDate=' + toDate + '&status=' + status + '&searchValue=' + searchValue)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  searchVerificationWithTenantIdDateRange(tenantId, fromDate, toDate, status, searchValue, offset, limit) {
    return this.http.get(this.baseUrl + '/verify/searchByDate/' + tenantId + '?offset=' + offset + '&limit=' + limit + '&fromDate=' + fromDate + '&toDate=' + toDate + '&status=' + status + '&searchValue=' + searchValue)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  download(id: string | undefined) {
    return this.http.get(`${this.baseUrl}/verify/pdf/${id}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  assignVideoCall(queueId,email){
    return this.http.put(`${this.baseUrl}/verify/assignagent/${queueId}/${email}`,{})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unAssignVideoCall(queueId){
    return this.http.put(`${this.baseUrl}/verify/removeagent/${queueId}`,{})
    .pipe(map((response: any) => {
      return response;
    }));
  }

  updateVerificationResultByAgent(id, body){
    return this.http.put(`${this.baseUrl}/verify/agent/verification/${id}`,
      body)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  verifySocial(id, body){
    return this.http.post(`${this.baseUrl}/social/${id}`,body) 
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getVerificationDetails(requestDTO) {
    return this.http.post(this.baseUrl + `/report/verification-details`, requestDTO)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getVideoVerificationStatusById(id){
    return this.http.get(this.baseUrl + `/verify/video-status/${id}`)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateVerificationStatusAsAgentFeedbackStatus(id){
    return this.http.put(this.baseUrl + '/verify/agent-status/' + id, null)
      .pipe(map((response: any) => {
        return response;
      }));
  }
}
