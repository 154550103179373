import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {AuthService} from './auth.service';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  baseUrl = environment.baseUrl;

  constructor(private authService:AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  if(request.url == this.baseUrl+'/metadata/import'){
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + this.authService.getAccessToken(),
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type'
  
        }
      });
    }else{
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + this.authService.getAccessToken(),
          'Content-Type':'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type'
  
        }
      });
    }
    
    return next.handle(request)
    .pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {

        if(error.status === 401){
          this.authService.logOut();
        }
        console.log(error);
        return throwError(error);
      })
    )
  }
}