import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AlertService } from '../../shared/_alert/alert.service';
import { ApplicationService } from '../application.service';
import { NgxSpinnerService } from  'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-button-view',
  template: `
    <button (click)="onClick()">Update Keys</button>
  `,
  styleUrls: ['./button-view.component.scss']
})
export class ButtonViewComponent implements ViewCell,OnInit {

  renderValue: string;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    public alertService: AlertService,
    private applicationService: ApplicationService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    
   }

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

  onClick() {  
    this.showStaticModal(this.rowData);
  }


  showStaticModal(rowData) {
    const modalRef = this.modalService.open(ConfirmationDialogComponent,{ size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = "Update application keys";
    modalRef.componentInstance.message = "Are you sure you want to update application keys?";
    modalRef.componentInstance.btnOkText = "Ok";
    modalRef.componentInstance.btnCancelText = "Cancel";

    modalRef.result.then((result) => {
        if (result) {
          this.applicationService.regenerateKeys(rowData.id)
          .subscribe(
            result => {
              this.spinner.show();
              this.alertService.success("Client ID and secret has been updated successfully", this.options)
              this.save.emit(this.rowData);
              this.spinner.hide();
            },
            error => {
              this.alertService.error("Client ID and secret updated failed", this.options)
              this.spinner.hide();
            }
          );
              }else{
                modalRef.close();
              }         
        });
  }

}
