import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApplicationService } from '../application.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../shared/_alert/alert.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.scss']
})
export class ApplicationViewComponent implements OnInit {

  clientId = '';
  clientSecret = '';
  applicationName = '';
  applicationDesc = '';
  serverURL = '';
  serverURLText = '';
  appId: Number;
  public show: boolean = false;
  enableEditView = false;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private applicationService: ApplicationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        this.appId = params['id'];
      });
    this.getApplication(this.appId);
  }


  getApplication(id) {
    this.spinner.show();
    this.applicationService.getApplicationById(id)
      .subscribe(application => {
        this.serverURL = application.serverURL,
          this.applicationName = application.name;
        this.applicationDesc = application.description;
        this.clientId = application.client_id;
        this.clientSecret = application.client_secret;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          console.log(error)
        });
  }

  onCopyUrl() {
    let url = this.serverURL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.info("Copied URL.", this.options)
  }

  downloadfile() {
    let thefile = [];
    this.applicationService.downloadApplicationPostman()
      .subscribe(data => { thefile.push(JSON.stringify(data)); console.log(data) },
        error => console.log("Error downloading the file."),
        () => {
          let url = window.URL.createObjectURL(new Blob(thefile, { type: "application/octet-stream" }));
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = 'IDMvalidate-Enduser.json';
          a.target = '_blank';
          a.click();
        });
  }


  onView() {

    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show)
      this.enableEditView = true;
    else
      this.enableEditView = false;
  }

  showStaticModal() {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = "Update application keys";
    modalRef.componentInstance.message = "Are you sure you want to update application keys?";
    modalRef.componentInstance.btnOkText = "Ok";
    modalRef.componentInstance.btnCancelText = "Cancel";
    modalRef.result.then((result) => {
      if (result) {
        this.applicationService.regenerateKeys(this.appId)
          .subscribe(
            result => {
              this.spinner.show();
              this.clientId = result.client_id;
              this.clientSecret = result.client_secret;
              this.alertService.success("Client ID and secret has been updated successfully", this.options)
              this.spinner.hide();
            },
            error => {
              this.alertService.error("Client ID and secret updated failed", this.options)
              this.spinner.hide();
            }
          );
      } else {
        modalRef.close();
      }
    });
  }

  onCopy() {
    let credentials = "client_id:" + this.clientId + "\n" + ",client_secret:" + this.clientSecret;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = credentials;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.info("Client ID and secret copied.", this.options)
  }



}

