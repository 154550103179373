import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verifications',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./verifications.component.scss']
})
export class VerificationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
