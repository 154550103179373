import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {UserComponent} from './user.component';
import {UserListComponent} from './user-list/user-list.component';
import { AppRoutingModule } from '../app-routing.module';
import { UserEditComponent } from './user-edit/user-edit.component';
import { NbIconModule } from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import {AlertModule} from '../shared/_alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    AppRoutingModule,
    NbToggleModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NbIconModule,
    AlertModule,
    NgbModule,
    NgxSpinnerModule
  ],
  declarations: [
    UserComponent,
    UserListComponent,
    UserEditComponent
  ]
})
export class UserModule { }
