import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { NbMenuService } from '../../node_modules/@nebular/theme';
import { Router, RoutesRecognized } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { AuthGuard } from './auth/auth-guard';
import { ProfileService } from './profile/profile.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  constructor(private authService: AuthService,
    private menuService: NbMenuService,
    private router: Router,
    private profileService: ProfileService) {
  }

  ngOnInit() {

    this.menuService.onItemClick()
    .subscribe((event) => {
      if (event.item.title === 'Logout') {
            this.authService.logOut();
          }
          if (event.item.title === 'Help') { 
            this.openLink();
          }
    });

    const isAuthenticated = this.authService.autoAuthUser();
    
  }

  openLink(){
    const user = this.profileService.getUserDetail();
   
    switch (user.userType) {
              case ('super_admin'): {
                window.open('https://idmerit.freshdesk.com/support/tickets/new', "_blank");
                this.router.navigateByUrl('/metadata');
                break;
              }
              case 'agent': {
                window.open('https://idmerit.freshdesk.com/support/tickets/new', "_blank");
                this.router.navigateByUrl('/verify');
                break;
              }
              default: {
                window.open('https://idmerit.freshdesk.com/support/tickets/new', "_blank");
                this.router.navigateByUrl('/dashboard'); 
                break;
              }
            }
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
