import { Component,Input,OnInit,EventEmitter,Output,ViewChild,ElementRef, AfterViewInit} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Cell, DefaultEditor, Editor } from 'ng2-smart-table'

@Component({
  selector: 'app-secret-view-encrypted',
  templateUrl: './secret-view-encrypted.component.html',
  styleUrls: ['./secret-view-encrypted.component.scss']
})
export class SecretViewEncryptedComponent  extends DefaultEditor implements AfterViewInit,OnInit {

  @ViewChild('name') name: ElementRef;

  constructor() {
    super();
   }
   
  ngAfterViewInit(): void {
      this.name.nativeElement.value = this.cell.newValue;
  }

   

  ngOnInit(): void {

  }

}
