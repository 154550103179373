import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { User } from '../model/user.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  headers = {
    headers: {
      'Content-Type':'application/json'
    }
  }

  baseUrl = environment.baseUrl;

  

  constructor(private http: HttpClient) {}


  

  getUsers(page,limit) {
    return this.http.get(this.baseUrl + '/user?page='+page+'&limit='+limit)
      .pipe(map((response : any) => {
        const users = response;
        const transformedUsers: User[] = [];
        for (const user of users) {          
          const tempUser: User = {
            id: user.id,        
            firstName:user.firstName,
            lastName:user.lastName,
            phone:user.phone,
            email:user.email,
            userType:user.userType,
            image: user.image,
            totalAttempt: user.totalAttempt
          };
          transformedUsers.push(tempUser);
        }
        return transformedUsers;
      }))
  }


  getUserById(userId: number) {
    return this.http.get( this.baseUrl + '/user/' + userId)
      .pipe(map((response: any) => {
        const user: User = {
          id: response.id,        
          firstName:response.firstName,
          lastName:response.lastName,
          phone:response.phone,
          email:response.email,
          userType:response.userType,
          image: response.image,
          totalAttempt: response.totalAttempt
        };
        return user;
      }))
  }


  createUser(user) {
    return this.http.post(this.baseUrl + '/user',
        user)
        .pipe(map((response: any) => {
            return response;
        }));
 }

 deleteUser(userId){
  return this.http.delete( this.baseUrl + '/user/' + userId)
  .pipe(map((response: any) => {
      return response;
  }));
 }


 editUser(user) {
  return this.http.put(this.baseUrl + '/user',
      user)
      .pipe(map((response: any) => {
          return response;
      }));
}

 

}
