import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";
import { Body } from '@angular/http/src/body';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  headers: HttpHeaders = new HttpHeaders();

 
  getEntries() {
    return this.http.get(this.baseUrl + '/metadata/entry')
      .pipe(map((response : any) => {
        return response;
      }))
  }

  downloadMetadataCSV(){
    this.headers.set('Content-Type','charset=utf-8')
    return this.http.get(this.baseUrl + '/metadata/download',{headers:this.headers})
    .pipe(map((response : any) => {
      return response;
    }))
  }

  createMetaData(entry){
    return this.http.post(this.baseUrl + '/metadata/entry',
    entry)
    .pipe(map((response: any) => {
        return response;
    }));
  }

  editMeataData(editEntry){
    return this.http.put(this.baseUrl + '/metadata/entry',
    editEntry)
    .pipe(map((response: any) => {
        return response;
    }));
  }

  deleteEntry(id){
    return this.http.delete(this.baseUrl + '/metadata/entry/' + id)
    .pipe(map((response: any) => {
        return response;
    }));
  }

  uploadFile(formData){
    return this.http.post(this.baseUrl + '/metadata/import', formData)
    .pipe(map((response: any) => {
        return response;
    }));

  }

  
}
