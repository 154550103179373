import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalDataSource } from 'ng2-smart-table';
import { AlertService } from '../../shared/_alert/alert.service';
import { ProfileService } from '../../profile/profile.service';
import { VerificationsService } from '../../verifications/verifications.service';
import { FormGroup } from '@angular/forms';
import { CheckboxButtonComponent } from '../checkbox-button/checkbox-button.component';

@Component({
  selector: 'app-configuration-detail',
  templateUrl: './configuration-detail.component.html',
  styleUrls: ['./configuration-detail.component.scss']
})


export class ConfigurationDetailComponent implements OnInit {


  doBVerification: Boolean;
  skipLiveness: Boolean;
  forgeryDetection: Boolean;
  skipInputName: Boolean;
  showScannedImages: Boolean;
  hideFooter: Boolean;
  enableCountryList: Boolean;
  enableLiveVideo: Boolean;
  defaultWebTemplate : Boolean;
  activeCallbackUrlAuthorization : Boolean;

  showComposedVideo: Boolean;
  showOtherInfo: Boolean; 
  showFooterTenantName: Boolean;
  showEndUserVerificationHistory : Boolean;
  geoLocationRequired: Boolean;

  form: FormGroup;
  userType = '';
  tenantList = [];
  isSuperAdmin = false;
  tenantID = 1;

  source: LocalDataSource = new LocalDataSource();
  
  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      country: {
        title: 'Country',
        type: 'String',
        width: '40%',
        filter: true
      },
      countryCode: {
        title: 'Country code',
        type: 'String',
        width: '20%',
        filter: true
      },
      cardType: {
        title: 'Card type',
        type: 'String',
        width: '20%',
        filter: false
      },
      metadataLive: {
        title: 'Metadata live',
        type: 'custom',
        renderComponent: CheckboxButtonComponent,
        width: '20%',
        filter: false
      }
    },
  };

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private verificationsService: VerificationsService,
    private route: ActivatedRoute,
    public alertService: AlertService,
  ) { }

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  tenantId = '';

  ngOnInit(): void {
    // this.tenantID = this.profileService.getTenantID();
    const user = this.profileService.getUserDetail();
    this.userType = user.userType;
    if (this.userType == 'super_admin') {
      this.isSuperAdmin = true;
      this.getTenantList()
    } else {
      this.getTenantDetail();
      this.getAllMetadataByCurrentTenant();
    }
  }

  onChangeSelect(event) {
    this.getTenantDetail(this.tenantID)
  }

  getAllMetadataByCurrentTenant() {
    this.configurationService.getAllMetadataByCurrentTenant().subscribe(metaData => {
      this.source.load(metaData);
    },
      (error) => {
        this.spinner.hide();
        console.log(error)
      });
  }


  getTenantList() {
    this.spinner.show();
    this.verificationsService.getTenantList().subscribe(tenants => {
      for (let tenant of tenants) {
        let row = { name: tenant.tenantName, value: tenant.identifier }
        this.tenantList.push(row);
      }
      (tenants.length > 0) ? this.spinner.hide() : this.spinner.show();
    },
      (error) => {
        this.spinner.hide();
        console.log(error)
      });
  }


  getTenantDetail(tenatId = null) {
    this.spinner.show();
    const api = tenatId ? this.configurationService.getConfigurationBySuperAdmin(tenatId) : this.configurationService.getConfiguration();
    api
      .subscribe(tenantDetail => {
        
        this.doBVerification = tenantDetail.doBVerification;
        this.skipLiveness = tenantDetail.skipLiveness;
        this.forgeryDetection = tenantDetail.forgeryDetection;
        this.skipInputName = tenantDetail.skipInputName;
        this.showScannedImages = tenantDetail.showScannedImages || null;
        this.hideFooter = tenantDetail.hideFooter || null;
        this.enableCountryList = tenantDetail.enableCountryList || null;
        this.enableLiveVideo = tenantDetail.enableLiveVideo || null;
        this.defaultWebTemplate = tenantDetail.defaultWebTemplate ;
        this.activeCallbackUrlAuthorization = tenantDetail.activeCallbackUrlAuthorization;
        this.showOtherInfo = tenantDetail.showOtherInfo || null;
        this.showFooterTenantName = tenantDetail.showFooterTenantName || null;
        this.showComposedVideo = tenantDetail.showComposedVideo;
        this.showEndUserVerificationHistory = tenantDetail.showEndUserVerificationHistory;
        this.geoLocationRequired = tenantDetail.geoLocationRequired;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          console.log(error)
        });
  }

  onUpdate() {
    let config = {}
    let serviceMethod = '';
    if (!this.isSuperAdmin) {
      config = {
        "doBVerification": this.doBVerification,
        "skipLiveness": this.skipLiveness,
        "forgeryDetection": this.forgeryDetection,
        "skipInputName": this.skipInputName
      }
      this.spinner.show();
      this.configurationService.updateConfiguration(config).subscribe((result) => {
        this.alertService.success("Configurations changes has done Sucessfully", this.options)
        this.spinner.hide();
      }, (e) => {
        this.spinner.hide();
        this.alertService.error(e.error.errorMessage, this.options)
      });
      this.spinner.hide();
    } else {
      config = {
        "doBVerification": this.doBVerification,
        "skipLiveness": this.skipLiveness,
        "forgeryDetection": this.forgeryDetection,
        "skipInputName": this.skipInputName,
        "showScannedImages": this.showScannedImages,
        "hideFooter": this.hideFooter,
        "enableCountryList": this.enableCountryList,
        "enableLiveVideo": this.enableLiveVideo,
        "defaultWebTemplate": this.defaultWebTemplate,
        "activeCallbackUrlAuthorization" : this.activeCallbackUrlAuthorization,
        "showOtherInfo": this.showOtherInfo,
        "showFooterTenantName": this.showFooterTenantName,
        "showComposedVideo": this.showComposedVideo,
        "showEndUserVerificationHistory": this.showEndUserVerificationHistory,
        "geoLocationRequired" : this.geoLocationRequired 

      }
      this.spinner.show();
      this.configurationService.updateConfigurationBySuperAdmin(this.tenantID, config).subscribe((result) => {
        this.alertService.success("Configurations changes has done Sucessfully", this.options)
        this.spinner.hide();
      }, (e) => {
        this.spinner.hide();
        this.alertService.error(e.error.errorMessage, this.options)
      });
    }

  }

}
