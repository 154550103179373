
import { Component, OnInit,Input,Output,EventEmitter, OnChanges } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss']
})
export class StatusLabelComponent implements OnInit,ViewCell {
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  renderValue: string;
  displayValue:string;
  buttonStyle={}


  constructor() { }

  ngOnInit() {
    this.changeValue(this.value);
  }

  changeValue(status) { 
    if (status) {
      this.displayValue = "Active";
      this.buttonStyle = this.styles.actve;
    } else {
      this.displayValue = "Inactive";
      this.buttonStyle = this.styles.inactive;
    }
  }


  styles = {
    actve: {
      'background-color': '#28a745',
      'color': 'white'
    },
    inactive: {
      'background-color': '#ffc107',
      'color': 'gray'
    }
  }

}
