<div class="row" id="headerBar">
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Application Details</h1>
          </div>
        </div>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body>
    <div class="container" id="user-form">

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="name">Application Name:</label>
          <label class="col-md-4 col-form-label" for="name">{{applicationName}}</label>
        </div>
      </div>

      <br>


      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="name">Description:</label>
          <label class="col-md-4 col-form-label" for="name">{{applicationDesc}}</label>
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="name">Server URL:</label>
          <input type="text" [disabled]='true' class="col-md-3 form-control" placeholder="Server URL"
            [(ngModel)]="serverURL">
          <div class="input-group-append" (click)="onCopyUrl()">
            <div style="cursor: pointer;" class="input-group-text">
              <span style="color:gray" class="fas fa-copy"></span>
            </div>
          </div>

        </div>
      </div>
      <br>


      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="username">Client Id:</label>
          <input type="text" [hidden]="!enableEditView" class="col-md-3 form-control" placeholder="Last Name"
            [(ngModel)]="clientId">
          <input type="password" [hidden]="enableEditView" class="col-md-3 form-control" placeholder="Last Name"
            [(ngModel)]="clientId">
        </div>
      </div>

      <br>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="username">Secret:</label>
          <input type="text" [hidden]="!enableEditView" class="col-md-3 form-control" placeholder="Last Name"
            [(ngModel)]="clientSecret">
          <input type="password" [hidden]="enableEditView" class="col-md-3 form-control" placeholder="Last Name"
            [(ngModel)]="clientSecret">
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="username">Postman collection:</label>
          <div class="col-md-4 input-group">
            <button type="button"
              style="padding: 5px;border-radius: 5px; background-color: indianred; border: none; margin-left: 0px;"
              (click)="downloadfile()" class="btn-primary">Download <nb-icon icon="download">
              </nb-icon></button>
          </div>
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-3 col-form-label" for="username">User Guide Document:</label>
          <div class="col-md-4 input-group">
              <a href="https://idmscan-doc.idmerit.com/" target="_blank"  class="btn-primary" style="padding: 5px;border-radius: 5px; background-color: rgb(92, 132, 205); border: none; margin-left: 0px;color: #ffffff;  text-decoration: none;">Click Me!</a>
          </div>
        </div>
      </div>



      <br>

      <div class="form-group row">
        <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
          [fullScreen]="true" size="medium">
          <p class="loading">Updating ....</p>
        </ngx-spinner>
        <div class="col-md-2 input-group">
          <button type="button" (click)="onView()" class="btn btn-primary btn-lg">View</button>
        </div>
        <div class="col-md-2 input-group">
          <button type="button" (click)="onCopy()" class="btn btn-primary btn-lg">Copy</button>
        </div>
        <div class="col-md-2 input-group">
          <button type="button" (click)="showStaticModal()" class="btn btn-primary btn-lg">Regenerate Keys</button>
        </div>
      </div>


    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->