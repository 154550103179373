import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeLayoutComponent } from './home-layout.component';
import { UserComponent } from './user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VerificationsComponent } from './verifications/verifications.component';
import { VerificationsListComponent } from './verifications/verifications-list/verifications-list.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { VerificationsDetailComponent } from './verifications/verifications-detail/verifications-detail.component';
import { DashboardViewComponent } from './dashboard/dashboard-view/dashboard-view.component';
import { AuthGuard } from './auth/auth-guard';
import { AuthLayoutComponent } from './auth-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthComponent } from './auth/auth.component';
import { PrivacyComponent } from './profile/privacy/privacy.component';
import { VerificationsEditComponent } from './verifications/verifications-edit/verifications-edit.component';
import { MetaComponent } from './meta/meta.component';
import { MetadataListComponent } from './meta/metadata-list/metadata-list.component';
import { MetadataEditComponent } from './meta/metadata-edit/metadata-edit.component';
import { MetadataUploadComponent } from './meta/metadata-upload/metadata-upload.component';
import { ApplicationComponent } from './application/application.component';
import { ApplicationListComponent } from './application/application-list/application-list.component';
import { ConfigurationDetailComponent } from './configuration/configuration-detail/configuration-detail.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ApplicationViewComponent } from './application/application-view/application-view.component';
import { AgentComponent} from './agent/agent.component';
import { VideoListComponent} from './agent/video-list/video-list.component';
import { ReportComponent } from './reports/report.component';
import { VerificationDetailsReportComponent } from './reports/verification-details-report/verification-details-report.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: UserListComponent },
          { path: 'new', component: UserEditComponent },
          { path: ':id/edit', component: UserEditComponent },
        ]
      },
      //https://idmerit.freshdesk.com/support/tickets/new

      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: DashboardViewComponent }
        ]
      },
      {
        path: 'verifications',
        component: VerificationsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: VerificationsListComponent },
          { path: ':id/view', component: VerificationsDetailComponent },
          { path: 'new', component: VerificationsEditComponent },
          { path: ':id/edit', component: VerificationsEditComponent },
        ]
      },
      {
        path:'verify',
        component: AgentComponent,
        canActivate: [AuthGuard],
        children:[
          { path:'', component : VideoListComponent},
          { path: ':id/view', component: VerificationsDetailComponent },
        ]
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'metadata',
        component: MetaComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: MetadataListComponent },
          { path: 'new', component: MetadataEditComponent },
          { path: 'upload', component: MetadataUploadComponent },
          { path: ':id/edit', component: MetadataEditComponent },

        ]
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ConfigurationDetailComponent },
        ]
      },
      {
        path: 'verifications',
        component: VerificationsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: VerificationsListComponent },
          { path: ':id/view', component: VerificationsDetailComponent },
          { path: 'new', component: VerificationsEditComponent },
          { path: ':id/edit', component: VerificationsEditComponent },
        ]
      },
      {
        path: 'reports',
        component: ReportComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: VerificationDetailsReportComponent },
        ]
      },
      {
        path: 'application',
        component: ApplicationComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ApplicationListComponent },
          { path: ':id/view', component: ApplicationViewComponent }
        ]
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        component: AuthComponent,
        children: [
          { path: 'login', component: LoginComponent },
        ]
      }
    ]
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard,
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }]
})
export class AppRoutingModule { }
