import { Component } from '@angular/core';

@Component({
  selector: 'app-auth',
  template: '<router-outlet></router-outlet>'
})
export class AuthComponent  {

 
}
