import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getTransactions() {
    
    return this.http.get(this.baseUrl + '/report/transactions')
      .pipe(map((response : any) => {
        return response;
      }))
  }

  getAggregationYearMonth(){
    return this.http.get(this.baseUrl + '/report/last-year-aggregation')
      .pipe(map((response : any) => {
        return response;
      }))
  }

  getAggregationDaily(){
    return this.http.get(this.baseUrl + '/report/daily-aggregation')
      .pipe(map((response : any) => {
        return response;
      }))
  }

}
