
import { Component, OnInit,Input,Output,EventEmitter, OnChanges } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-view-link',
  templateUrl: './view-link.component.html',
  styleUrls: ['./view-link.component.scss']
})
export class ViewLinkComponent implements OnInit,ViewCell {
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  renderValue: string;


  constructor() { }

  ngOnInit() {
    this.renderValue=String(this.value);
  }

}
