<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Privacy Settings</h1>
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Change Password</h1>
          </div>
        </div>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body>
    <div class="container" id="user-form">
        <!--div class="row">               
                 <div class="form-group detail-input-group">
                   <label class="col-md-2 col-form-label" for="name">Email: </label>
                   <input  type="text" class="col-md-10 form-control" id="email" placeholder="Email" [(ngModel)]="email">
                 </div>             
             </div>
   
             <br-->

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="username">Current Password: </label>
            <input type="password" class="col-md-10 form-control" id="currentPassword" placeholder="Current Password"
              [(ngModel)]="currentPassword">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="password">New Password: </label>
            <input type="password" class="col-md-10 form-control" id="newPassword" placeholder="New Password"
              [(ngModel)]="newPassword" minlength="8">
            <app-password-strength-bar [passwordToCheck]="newPassword" [barLabel]="barLabel">
            </app-password-strength-bar>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group detail-input-group">
            <label class="col-md-2 col-form-label" for="password">Retype Password: </label>
            <input type="password" class="col-md-10 form-control" id="reTypePassword" placeholder="Retype Password"
              [(ngModel)]="reTypePassword" minlength="8">
          </div>
        </div>

        <br>

        <br>
        <div class="row" id="saveButtonDiv">
          <div class="form-inline">
            <div class="col-md-12 input-group-sm form-group detail-input-group">
              <button type="submit" [disabled]="enableChangePassword()" class="btn btn-primary btn-lg"
                (click)="onSave()">Change Password</button>
            </div>
          </div>
        </div>
    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->