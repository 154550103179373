import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { User } from 'src/app/model/user.model';
import { Subject } from 'rxjs';
import { AlertService } from './../shared/_alert/alert.service';
import { map } from "rxjs/operators";


export type AuthUser = {
  email: String;
  firstName: String;
  lastName: String;
  image: String;
  userType: string;
};

export type AuthToken = {
  accessToken: String;
  tokenType: String;
  expiresIn: String;
}

export type LoginEntries = {
  clientId: String;
  clientSecret: String;
  tenantId: String;
  tenantLogo: String;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authStatusListener = new Subject<boolean>();
  private isAuthenticated = false;
  private token: string;
  private tenantImageString: string;
  private userName: string;
  private userType: string;
  private user: {};
  private userChanged = new Subject<number>();
  private clientId: string;
  private clientSecret: string;
  private tenantId: string;
  private tenantLogo: string;


  public authUser: Partial<AuthUser>;
  public authToken: Partial<AuthToken>;
  public loginEntries: Partial<LoginEntries>;

  baseUrl = environment.baseUrl;

  private authHttpClient: HttpClient;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };



  private setHttpHeaders(clientId, clientSecret) {
    let usernamePasswd = clientId + ':' + clientSecret;

    return new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', "Basic " + btoa(usernamePasswd));

  }

  constructor(backend: HttpBackend,
    private router: Router,
    public alertService: AlertService) {
    this.authHttpClient = new HttpClient(backend);
  }

  basicLogin(email: String, password: String) {
    return this.authHttpClient.post(this.baseUrl +'/user/login', {email: email, password: password})
    .pipe(map((response :any)=>{

      this.authUser = {     
        firstName:response.firstName,
        lastName:response.lastName,
        email:response.email,
        image:response.image,
        userType:response.userType
      };

      this.loginEntries={
        clientId: response.loginEntries[0].client_id,
        clientSecret : response.loginEntries[0].client_secret,
        tenantId : response.loginEntries[0].tenantID,
        tenantLogo:response.loginEntries[0].tenantLogo
      }
      
      this.userName = response.firstName +' '+response.lastName;
      this.tenantImageString=response.loginEntries[0].tenantLogo;
      this.userType = response.userType;
      
      this.saveAuthDataToLoalStorage(this.loginEntries, this.authUser);
      this.getToken(this.loginEntries.clientId,this.loginEntries.clientSecret);
      this.isAuthenticated = true;
      return this.isAuthenticated;
    }))
  }
     /* .subscribe((response: any) => {
        this.authUser = {     
          firstName:response.firstName,
          lastName:response.lastName,
          email:response.email,
          image:response.image 
        };

        this.loginEntries = {
          clientId: response.loginEntries[0].client_id,
          clientSecret: response.loginEntries[0].client_secret,
          tenantId: response.loginEntries[0].tenantID,
          tenantLogo: response.loginEntries[0].tenantLogo
        }

        this.userName = response.firstName + ' ' + response.lastName;
        this.tenantImageString = response.loginEntries[0].tenantLogo;
        this.userType = response.userType;

        this.saveAuthDataToLoalStorage(this.loginEntries, this.authUser);
        this.getToken(this.loginEntries.clientId, this.loginEntries.clientSecret);
        this.isAuthenticated = true;
        return this.isAuthenticated;
      }))
  }
  /* .subscribe((response: any) => {
     this.authUser = {     
       firstName:response.firstName,
       lastName:response.lastName,
       email:response.email,
       image:response.image
     };

     this.loginEntries={
       clientId: response.loginEntries[0].client_id,
       clientSecret : response.loginEntries[0].client_secret,
       tenantId : response.loginEntries[0].tenantID,
       tenantLogo:response.loginEntries[0].tenantLogo
     }
     
     this.userName = response.firstName +' '+response.lastName;
     this.tenantImageString=response.loginEntries[0].tenantLogo;
     
     this.saveAuthDataToLoalStorage(this.loginEntries, this.authUser);
     this.getToken(this.loginEntries.clientId,this.loginEntries.clientSecret);
     this.isAuthenticated = true;

   }, (e) => {
     this.isAuthenticated = false;
     if(e.status===401){
       this.alertService.error("Invalid Credentials", this.options)
     }else{
     this.alertService.error("Somethings wrong", this.options)
     console.log(e);
     }
   });
}*/


  resetPassword(email) {
    return this.authHttpClient.post(this.baseUrl + '/user/forgotpassword', { email: email }).pipe(map((response: any) => {
      return response;
    }))
  }



  private getToken(clientId, clientSecret) {
    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('scope', 'idmvalidate');

    this.authHttpClient.post(this.baseUrl + '/token', body.toString(),
      {
        headers: this.setHttpHeaders(clientId, clientSecret)
      }
    ).subscribe((response: any) => {
      this.authToken = {
        accessToken: response.access_token,
        tokenType: response.token_type,
        expiresIn: response.expires_in
      }
      this.token = response.access_token;

      this.saveAccessTokenToLocalStorage(this.authToken)
      // if (this.userType !== 'super_admin') {
      //   this.router.navigateByUrl('/dashboard');
      // } else {
      //   this.router.navigateByUrl('/metadata');
      // }

      switch (this.userType) {
        case ('super_admin'): {
          this.router.navigateByUrl('/metadata');
          break;
        }
        case 'agent': {
          this.router.navigateByUrl('/verify');
          break;
        }
        default: {
          this.router.navigateByUrl('/dashboard'); 
          break;
        }
      }

      //this.router.navigateByUrl('/');
    });

    return this.authToken;

  }

  getTenantLogo() {
    return this.tenantImageString;
  }

  getAccessToken() {
    return this.token;
  }

  getUserName() {
    return this.userName;
  }

  private saveAuthDataToLoalStorage(loginEntries, user) {
    localStorage.setItem('client_id', loginEntries.clientId);
    localStorage.setItem('client_secret', loginEntries.clientSecret);
    localStorage.setItem('tenantID', loginEntries.tenantId);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('tenantLogo', loginEntries.tenantLogo);
  }


  private saveAccessTokenToLocalStorage(authToken) {

    localStorage.setItem('access_token', authToken.accessToken);
    localStorage.setItem('token_type', authToken.tokenType);
    localStorage.setItem('expires_in', authToken.expiresIn);
  }

  isUserAuthenticated() {
    return this.isAuthenticated;
  }


  getUserDetail() {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  }


  autoAuthUser() {

    this.token = localStorage.getItem('access_token');

    if (!this.token) {
      return false;
    } else {
      const authUser = JSON.parse(localStorage.getItem('user'));
      this.userName = authUser.firstName + ' ' + authUser.lastName;
      this.userType = authUser.userType;
      this.tenantImageString = localStorage.getItem('tenantLogo');
      this.isAuthenticated = true
      return true;
    }

  }





  getUser() {
    return this.authUser;
  }

  private clearAuthDataFromLocalStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('tenantLogo');
    localStorage.removeItem('client_id');
    localStorage.removeItem('client_secret');
    localStorage.removeItem('tenantID');
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_in');
  }

  logOut() {
    this.isAuthenticated = false;
    this.clearAuthDataFromLocalStorage();
    window.location.replace('/auth/login');
  }

}