import { Component,Input,OnInit,EventEmitter,Output,ViewChild,ElementRef} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-secret-view',
  templateUrl: './secret-view.component.html',
  styleUrls: ['./secret-view.component.scss']
})
export class SecretViewComponent implements OnInit,ViewCell {

  constructor() { 
  }

  value: string | number;
  @Input() show: boolean;
  @Input() rowData: any;
  //@Output() showValue = new EventEmitter();


  renderValue: string;

  ngOnInit(): void {
    this.renderValue=String(this.value);
  }

  
  

}
