import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-type-label',
  templateUrl: './user-type-label.component.html',
  styleUrls: ['./user-type-label.component.scss']
})
export class UserTypeLabelComponent implements OnInit {
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter();
  
    SYSTEM_USER = "system";
    SUPER_ADMIN = "super_admin";
    TENANT_ADMIN = "tenant_admin";
    TENANT_USER = "tenant_user";
    VERIFICATION_USER = "verification_user";
    AGENT = "agent";
    ANONYMOUS = "anonymous";

    SYSTEM_USER_LABEL = "System";
    SUPER_ADMIN_LABEL  = "Super Admin";
    TENANT_ADMIN_LABEL = "Tenant Admin";
    TENANT_USER_LABEL  = "Tenant User";
    VERIFICATION_USER_LABEL  = "Verification User";
    AGENT_LABEL  = "Agent";
    ANONYMOUS_LABEL  = "Anonymous";

    renderValue: string;

    constructor() { }

  // ngOnInit(): void {
  //   this.renderValue=String(this.value);
  // }

  ngOnInit() {
    this.changeValue(this.value);
  }

  changeValue(value) {
    if (value === this.SYSTEM_USER) {
      return this.renderValue = this.SYSTEM_USER_LABEL;
    } else if (value === this.SUPER_ADMIN) {
      return this.renderValue = this.SUPER_ADMIN_LABEL;
    } else if (value === this.TENANT_ADMIN) {
      return this.renderValue = this.TENANT_ADMIN_LABEL;
    } else if (value === this.TENANT_USER) {
      return this.renderValue = this.TENANT_USER_LABEL;
    } else if (value === this.VERIFICATION_USER) {
      return this.renderValue = this.VERIFICATION_USER_LABEL;
    } else if (value === this.AGENT) {
      return this.renderValue = this.AGENT_LABEL;
    } else if (value === this.ANONYMOUS) {
      return this.renderValue = this.ANONYMOUS_LABEL;
    } else {
      return this.renderValue = "";
    }
  }
}
