<div class="login-background"></div>


<div class="container" [hidden]="showLogin">

  <div id="main_row" class="row justify-content-md-center">

    <alert></alert>

    <div class="card">
      <div class="card-body" id="login-card-body">
        <div class="card-title mb-3">
          <p class="login-box-msg">Sign in to your account.</p>
          <alert></alert>
        </div>

        <br>
        <form role="search" class="navbar-form-custom" [formGroup]="form" (ngSubmit)="basicLogin()">
          <div class="input-group mb-3">
            <input type="email" class="form-control" formControlName="email" placeholder="Email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>

          <div class="input-group mb-3">
            <input type="password" class="form-control" formControlName="password" placeholder="Password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-8">
                <div class="card-body">
                  <div #recaptcha></div>
                </div>
            </div>
          </div>


          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <!--<input type="checkbox" id="remember">
                <label for="remember">
                  Remember Me
                </label>>-->
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse-rise"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
              </ngx-spinner>
              <button type="submit" class="btn btn-primary btn-block" [disabled]="!form.valid">Sign In</button>

            </div>
            <!-- /.col -->
          </div>




        </form>
        <div class="row justify-content-md-center">
          <div class="col-6">
            <button id="forgetPassword" (click)="forgetPassword()">Forget password ?</button>
          </div>
        </div>
        <!--<p class="mb-1">
          <center><a href="forgot-password.html">I forgot my password</a></center>
        </p>-->
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</div>




<div class="container" [hidden]="enableForgePassword">
  <div id="main_row" class="row justify-content-md-center"></div>
  <alert></alert>
  <div class="card">
    <div class="card-body" id="forgetPass-card-body">
      <form [formGroup]="forgotPwdForm" (ngSubmit)="resetPassword()">
        <div class="input-group mb-3">
          <input type="email" class="form-control" formControlName="email" placeholder="Email">
          <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse-rise"
            [fullScreen]="true">
            <p style="color: white"> Requesting a new password... </p>
          </ngx-spinner>
          <div class="input-group-append">
            <button type="submit" class="fas fa-envelope" id="resetPassButton"> Reset Password</button>
          </div>
          <div class="input-group-append">
          <!-- <div class="row"> -->
            <!-- <div class="col-8"> -->
                <!-- <div class="card-body"> -->
                  <div #recaptcha></div>
                <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </form>

      <div class="row justify-content-md-center">
        <!-- /.col -->
        <div class="col-4">
          <button (click)="hiddenForgetPassword()" class="btn btn-primary btn-block">Back</button>
        </div>
        <!-- /.col -->
      </div>
    </div>
  </div>
</div>
