import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbLayoutModule, NbMenuModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationModule } from './application/application.module';
import { AuthLayoutComponent } from './auth-layout.component';
import { AuthModule } from './auth/auth.module';
import { HeaderInterceptor } from './auth/header.interceptor';
import { ConfigurationModule } from './configuration/configuration.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { HomeLayoutComponent } from './home-layout.component';
import { MetaModule } from './meta/meta.module';
import { ProfileModule } from './profile/profile.module';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { AlertModule, AlertService } from './shared/_alert';
import { AlertComponent } from './shared/_alert/alert.component';
import { UserModule } from './user/user.module';
import { UserService } from './user/user.service';
import { StatusLabelComponent } from './verifications/shared-model/status-label/status-label.component';
import { VerificationsModule } from './verifications/verifications.module';
import { AgentModule } from './agent/agent.module';
import { UserTypeLabelComponent } from './agent/user-type-label/user-type-label.component';
import { BooleanLabelComponent } from './verifications/shared-model/boolean-label/boolean-label.component';
import { ReportsModule } from './reports/reports.module';
import { ExportService } from './reports/export.service';
import { VideoVerificationComponent } from './agent/video-verification/video-verification.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    AuthLayoutComponent, 
    UserTypeLabelComponent,
  ],
  imports: [
    AuthModule,
    HttpClientModule,
    HttpModule,
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    AlertModule,
    NbLayoutModule,
    NgxSpinnerModule,
    NbSidebarModule,
    NbMenuModule,
    ConfigurationModule,
    UserModule,
    ApplicationModule,
    MetaModule,
    VerificationsModule,
    NbEvaIconsModule,
    AuthModule,
    DashboardModule,
    ProfileModule,
    NgbModule,
    AgentModule,
    ReportsModule
  ],
  entryComponents: [
    StatusLabelComponent,
    ConfirmationDialogComponent,
    AlertComponent, 
    BooleanLabelComponent,
    VideoVerificationComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    UserService,
    AlertService,
    ExportService],
  bootstrap: [AppComponent]
})
export class AppModule { }
