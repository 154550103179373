<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">

      <div class="row" id="headerBar">
        <h1 id="pageTopic">Verifications</h1>
      </div>


      <br>
      <br>
      <br>
      <br>
      <br>
      <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
      [fullScreen]="true" size="medium">
      <p class="loading">Loading....</p>
      </ngx-spinner>

      <div class="input-group offset-md-11 col-md-1">
        <button type="button" (click)="onCreate()" class="btn btn-primary btn-md">New Verification</button>
      </div>

      <div class="row">
        <div class="col-sm-3">
          <div class="input-group">
            <input #search class="form-control form-control-navbar" [(ngModel)]="searchValue" id="searchInput" 
              type="search" (keyup.enter)="onSearch()" placeholder="Search" aria-label="Search">
            <div class="input-group-append">
              <button type="button" (click)="onSearch()" class="btn btn-primary btn-md">Search</button>
            </div>
          </div> 
        </div>
        <div class="col-sm-1"> 
          <!-- <div>
            <nb-select class="status-select" [(selected)]="product" [(ngModel)]="product" 
                (selectedChange)="onChangeSelect($event)" placeholder="Select Product">
                <nb-option *ngFor="let product of productList | keyvalue" [value]="product.value">{{product.key}}</nb-option>
            </nb-select> 
          </div> -->
          <div  class="input-group">
            <nb-select class="tenant-select" [(selected)]="tenantID" [(ngModel)]="tenantID" [hidden]='!isSuperAdmin'
              (selectedChange)="onChangeSelect($event)" placeholder="Select Tenant">
              <nb-option *ngFor="let item of tenantList" [value]="item.value">{{item.name}}</nb-option>
            </nb-select>
          </div>
          <div  class="input-group">
            <nb-select class="status-select" [(selected)]="status" [(ngModel)]="status" [hidden]='isSuperAdmin'
              (selectedChange)="onChangeSelect($event)" placeholder="Select Status">
              <nb-option *ngFor="let status of statusList | keyvalue" [value]="status.value">{{status.key}}</nb-option>
            </nb-select>

            <nb-select class="status-select" [(selected)]="status" [(ngModel)]="status" [hidden]='!isSuperAdmin'
              (selectedChange)="onChangeSelect($event)" placeholder="Select Status">
              <nb-option *ngFor="let status of statusList | keyvalue" [value]="status.value">{{status.key}}</nb-option>
            </nb-select>
          </div>
        </div> 
        <div class="col-sm-2"></div>
        <div class="col-sm-6" style="align-items: right;">
          <div class="input-group raw">
            <label class="col-form-label" for="fromDate">From Date:</label>
            <div  class="column"> 
                <input type="date" class="form-control" width="25px;" id="fromDate" placeholder="dd-MM-yyyy" max="{{maxDate | date:'yyyy-MM-dd'}}" [(ngModel)]="fromDate" (keydown)="disableDate()" >
            </div>
           
            <label class="col-form-label" for="toDate">To Date:</label>
            <div  class="column">
              <input type="date" class="form-control" id="toDate" placeholder="dd-MM-yyyy" max="{{maxDate | date:'yyyy-MM-dd'}}" [(ngModel)]="toDate" (keydown)="disableDate()">
            </div> 

            <div  class="column">
              <button type="button" (click)="onDateSearch()" class="btn btn-primary btn-md ml-2">Search</button>
              <button type="button" [hidden]='!isTenantAdmin' (click)="downloadCSV()" class="btn btn-primary btn-md ml-2"><i class="fas fa-cloud-download-alt" aria-hidden="true" title="Export .csv"></i>  Download </button>
            </div>
          </div>

          
        </div>
      </div> 
 
    </div><!-- /.container-fluid -->
  </div>

</div>

<br>

<nb-card>

  <nb-card-body>
    <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
      [fullScreen]="true" size="medium">
      <p class="loading">Loading....</p>
    </ngx-spinner>
    <!-- <div class="container-fluid" [hidden]='isSuperAdmin'>
      <div class="row justify-content">
        <div class="col-md-offset-11 col-md-1"> 
          <a class="btn-link" [routerLink]="'/reports'">.CSV</a> 
        </div> 
      </div> 
    </div> -->
    <ng2-smart-table class="tbl-col dataTable" id='testTable' [settings]="tableSettings" [source]="source"  [hidden]='isSuperAdmin'
      (delete)="showStaticModal($event)" (userRowSelect)="onUserRowSelect($event)" (custom)="onView($event)">
    </ng2-smart-table>

    <ng2-smart-table class="tbl-col dataTable" id='testTable' [settings]="tableSettingsSuperAdmin" [source]="source"  [hidden]='!isSuperAdmin'
      (delete)="showStaticModal($event)" (userRowSelect)="onUserRowSelect($event)" (custom)="onView($event)">
    </ng2-smart-table>

    <div class="container-fluid">
      <div class="row justify-content">
        <div class="col-md-offset-9 col-md-1">
          <button type="button" [disabled]='disablePrevButton' (click)="onBack()"
            class="btn btn-primary btn-lg paginate-button">Previous</button>
        </div>
        <div class="col-md-1">
          <button type="button" [disabled]="disableNxtButton || dataSize<15" (click)="onNext()"
            class="btn btn-primary btn-lg paginate-button">Next</button>
        </div>
      </div> 
    </div>
  </nb-card-body>
</nb-card>
