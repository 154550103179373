import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AppRoutingModule } from '../app-routing.module';
import { NbIconModule } from '@nebular/theme';
import { NgxSpinnerModule } from 'ngx-spinner';
import {AlertModule} from '../shared/_alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetadataListComponent } from './metadata-list/metadata-list.component';
import {MetaComponent} from './meta.component';
import { MetadataEditComponent } from './metadata-edit/metadata-edit.component';
import { MetadataUploadComponent } from './metadata-upload/metadata-upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import {StatusLabelComponent } from './status-label/status-label.component';
import { EngineLabelComponent } from './engine-label/engine-label.component';


@NgModule({
  declarations: [MetadataListComponent,MetaComponent, MetadataEditComponent, MetadataUploadComponent,StatusLabelComponent, EngineLabelComponent],
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    AppRoutingModule,
    NbToggleModule,
    NbCardModule,
    FormsModule,
    NbIconModule,
    AlertModule,
    NgbModule ,
    NgxSpinnerModule,
    ReactiveFormsModule 
  ]
})
export class MetaModule { }
