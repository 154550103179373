import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationsListComponent } from './verifications-list/verifications-list.component';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AppRoutingModule } from '../app-routing.module';
import { NbIconModule, NbSelectModule} from '@nebular/theme';
import { VerificationsComponent } from './verifications.component';
import { VerificationsDetailComponent } from './verifications-detail/verifications-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {LabelValueComponent} from './shared-model/label-value/label-value.component';
import {StatusLabelComponent } from './shared-model/status-label/status-label.component';
import {ViewLinkComponent} from './shared-model/view-link/view-link.component';
import { VerificationsEditComponent } from './verifications-edit/verifications-edit.component';
import {AlertModule} from '../shared/_alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { SafePipe } from './safe.pipe';
import {TooltipDirective} from '../shared/tooltip.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgImageSliderModule } from 'ng-image-slider';
import { EditLinkComponent } from './shared-model/edit-link/edit-link.component';


@NgModule({
  declarations: [
    VerificationsComponent,
    VerificationsListComponent,
    VerificationsDetailComponent,
    LabelValueComponent,
    StatusLabelComponent,
    VerificationsEditComponent,
    SafePipe,
    TooltipDirective,
    ViewLinkComponent,
    EditLinkComponent
  ],
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    AppRoutingModule,
    NbToggleModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NbIconModule,
    NbSelectModule,
    AlertModule,
    NgbModule ,
    NgxSpinnerModule,
    NgSelectModule,
    NgImageSliderModule
  ]
})

export class VerificationsModule { }
