<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;{{pageTitleLabel}}</h1>
</div>
<br>
<br>
<br>
<br>
<br>
<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Enter Verification Details</h1>
          </div>
        </div>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body>
    <div class="container" id="form">

      <form [formGroup]="verificationGroup" (ngSubmit)="onSave()" #formDir="ngForm">
        <div class="row" [hidden]="!editMode">
          <div [formGroup]="verificationGroup" class="form-group">
            <label class="col-md-2 col-form-label" for="mobileNumber">Transaction Id:</label>
            <input type="text" class="col-md-10 form-control" id="Identifier" placeholder="Identifier"
              [(ngModel)]="identifier" formControlName="identifier" placement="top" delay="250">
          </div>
        </div>

        <br>

        <div class="row">
          <div [formGroup]="verificationGroup" class="form-group">
            <label class="col-md-2 col-form-label" for="mobileNumber">Mobile number:</label>
            <input type="text" class="col-md-10 form-control" id="mobileNumber" placeholder="Mobile Number"
              [(ngModel)]="mobileNumber" formControlName="mobileNumber" tooltip="mobile number has to be +12055551234"
              placement="top" delay="250">
          </div>
        </div>

        <div *ngIf="skipInputName" >
          <br>
          <div class="row">
            <div [formGroup]="verificationGroup" class="form-group">
              <label class="col-md-2 col-form-label" for="personName">Name:
              </label>
              <input type="text" class="col-md-10 form-control" id="personName" placeholder="Name" [(ngModel)]="personName"
                formControlName="personName" tooltip="Name should match what is on the ID" placement="top" delay="250">
            </div>
          </div>
        </div>

        <div *ngIf="!skipInputName">
          <br>
          <div class="row">
            <div [formGroup]="verificationGroup" class="form-group">
              <label class="col-md-2 col-form-label" for="personName">Name:<span class="required-asterisk">*</span>
              </label>
              <input type="text" class="col-md-10 form-control" id="personName" placeholder="Name" [(ngModel)]="personName"
                formControlName="personName" tooltip="Name should match what is on the ID" placement="top" delay="250">
            </div>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group">
            <label class="col-md-2 col-form-label" for="password">Country:<span class="required-asterisk">*</span>
            </label>
            <!--select class="col-md-3 form-control"  formControlName="country" [(ngModel)]="countryName" (change)="selectCountry($event)">
                     <option *ngFor="let item of countryList | keyvalue" [value]="item.key" [selected]="US">
                       {{item.value}}
                     </option>
                  </select-->
            <ng-select class="col-md-3 form-control" (add)="setcountryNameCode()" (change)="setcountryNameCode()" formControlName="country" [(ngModel)]="country">
              <ng-option *ngFor="let item of countryList"  [value]="item.name">{{item.name}}</ng-option>
            </ng-select> 
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group">
            <label class="col-md-2 col-form-label" for="requestId">Request ID:<span class="required-asterisk ">*</span>
            </label>
            <input type="text" class="form-control" id="requestId" [(ngModel)]="requestId" formControlName="requestId">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group">
            <label class="col-md-2 col-form-label" for="dateOfBirth">Date of Birth:</label>
            <input type="date" class="form-control" id="dateOfBirth" placeholder="Date Of Birth"
              [(ngModel)]="dateOfBirth" formControlName="dateOfBirth">
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group">
            <label class="col-md-2 col-form-label" for="callbackURL">Callback URL:</label>
            <input type="text" class="form-control" id="callbackURL"
              placeholder="URL pattern : http://example.com or https://example.com" [(ngModel)]="callbackURL"
              formControlName="callbackURL" (keyup)="onCallbackURL($event)">
            <div *ngIf="!this.callBackURLMatched"
              class="col-md-4 col-form-label cross-validation-error-message alert alert-danger">
              Callback URL pattern is not matched.
            </div>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="form-group">
            <label class="col-md-2 col-form-label" for="redirectURL">Redirect URL:</label>
            <input type="text" class="form-control" id="redirectURL"
              placeholder="URL pattern : http://example.com or https://example.com" [(ngModel)]="redirectURL"
              formControlName="redirectURL" (keyup)="onRedirectURL($event)">
            <div *ngIf="!this.redirectBackURLMatched"
              class="col-md-4 col-form-label cross-validation-error-message alert alert-danger">
              Redirect URL pattern is not matched.
            </div>
          </div>
        </div>

        <br>
        
        <div class="row" *ngIf="this.enableLiveVideo">
          <div class="form-group">
            <label class="col-md-2 col-form-label" for="isLiveVideo">Live Video:</label>
            <nb-toggle [(ngModel)]="isLiveVideo" formControlName="isLiveVideo"></nb-toggle>
          </div>
        </div>

        <br>
        <br>

        <div class="form-group row">
          <div class="col-md-2 col-md-offset-10 input-group form-group">
            <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
              [fullScreen]="true" size="medium">
              <p class="loading">Updating ....</p>
            </ngx-spinner>
            <button type="submit" [disabled]="verificationGroup.invalid || !this.callBackURLMatched || !this.redirectBackURLMatched"
              class="btn btn-primary btn-lg">Save</button>
          </div>
        </div>
      </form>

    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->