import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../application.service'
import { NgxSpinnerService } from  'ngx-spinner';
import {SecretViewComponent} from '../secret-view/secret-view.component';
import {ButtonViewComponent} from '../button-view/button-view.component';
import {SecretViewEncryptedComponent} from '../secret-view-encrypted/secret-view-encrypted.component';
import { AlertService } from '../../shared/_alert/alert.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit {


 source: LocalDataSource = new LocalDataSource();
 enableEditView=false;
 private selectedRow: any;
 public show:boolean = false;

 options = {
  autoClose: true,
  keepAfterRouteChange: true
};


 tableSettings = {
  mode: 'inline',
  actions: {
    columnTitle: '',
    add: false,
    edit: false,
    delete: false,
    width: '10%',
    custom: [
      { name: 'viewrecord', title: 'View'}
    ],
    position: 'right'
  },
   edit: {
     editButtonContent: '<i class="fas fa-eye"></i>',
     saveButtonContent: '<i class="fas fa-copy"></i>',
     cancelButtonContent: '<i class="fas fa-eye-slash"></i>',
     confirmSave: true,
   },
   delete: {
     deleteButtonContent: '<i class="fas fa-trash"></i>',
     confirmDelete: true,
   },
   columns: {
     name: {
       title: 'Application Name',
       type: 'String',
       width: '30%',
       filter: false,
       editable: false,
     },
     description: {
       title: 'Description',
       type: 'String',
       width: '30%',
       filter: false,
       editable: false,
     },
     client_id: {
       title: 'Client ID',
       type: 'String',
       //renderComponent: SecretViewComponent,
       width: '30%',
       filter: false
     }/*,
     client_secret: {
       title: 'Client Secret',
       type: 'custom',
       renderComponent: SecretViewComponent,
       editor: {
        type: 'custom',
        component: SecretViewEncryptedComponent,
      },
       width: '20%',
       filter: false
     }*/
   },
 };


 

 
userdata:any;
 constructor(
   private applicationService: ApplicationService,
   private router: Router,
   private spinner: NgxSpinnerService,
   public alertService: AlertService,
   private route: ActivatedRoute
 ) {
   
  }

  onCreate(): void {
   this.router.navigate(['new'], { relativeTo: this.route });
 }

loadApplcaicationList() {
   this.spinner.show();
   this.applicationService.getApplicationList()
     .subscribe(applications => {
      console.log(applications);
       this.source.load(applications);
       this.spinner.hide();
     },
       (error) => {
         this.spinner.hide();
         console.log(error)
       });
 }

 ngOnInit(): void {
   this.tableSettings=Object.assign({},this.tableSettings); 
   this.loadApplcaicationList();
 }


 onSearch(query: string = '') {
   if(query == ''){
     this.source.reset();
   }  else{
     this.source.setFilter([
       // fields we want to include in the search
       {
         field: 'name',
         search: query
       },
       {
         field: 'client_id',
         search: query
       }
     ], false);
   } 

 }


 onUserRowSelect(event): void {
   // this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  showStaticModal(rowData: any) {
  }

onShow(event){
  this.selectedRow = event.data;
  this.source.onUpdated = event.data.client_secret;
}


onView(event): void {
  this.router.navigate([event.data.id, 'view'], { relativeTo: this.route });
}


onCopy(event){
  let credentials = "client_id:"+ event.newData.client_id+"\n"+"client_secret:"+ event.newData.client_secret;
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = credentials;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.alertService.info("Client ID and secret copied.", this.options)
}


}
