<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="col-md-11">
                <h3 id="verification-title">
                  Transaction ID:
                  <strong>{{ this.identifier }}</strong> &nbsp;
                </h3>
                <h3 id="verification-title">
                  <strong > ({{timeStamp}} ) </strong>
                  <strong > {{"GMT"+ timezone}}  </strong>
                  &nbsp;&nbsp;
                </h3>
            </div>
            <div class="col-md-1">
                <strong [ngStyle]="changeRequestTypeColor(liveVideo)" class="m-2" id="statusLable"
                *ngIf="liveVideo === 'Live'">{{ requestType }}</strong>
            </div>
          </div>
          <div class="col-lg-4" id="videoButton">
            <div class="raw">
              <div class="form-group detail-input-group col-lg-2" [hidden]="!(userType == 'agent')">
                <button type="button" (click)="updateVideoStatus()" class="btn btn-primary btn-lg">
                  {{ verifyStatus == "pending"? "Assign Video" : "Unassign Video" }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button type="button" id="editbutton" [hidden]="userType == 'super_admin' || userType == 'tenant_user' || userStatus == 'timed_out' || userStatus == 'error'"
              (click)="onEditStatus()" class="btn btn-primary btn-lg">
              Edit
            </button>
            &nbsp;&nbsp;
            <button type="button" id="backButton" (click)="onBack()" class="btn btn-primary btn-lg">
              <nb-icon icon="arrow-back"></nb-icon> Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
      [fullScreen]="true" size="medium">
      <p class="loading">Loading....</p>
    </ngx-spinner>

    <!-- right col (We are only adding the ID to make the widgets sortable)-->

    <section class="col-lg-12 connectedSortable">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Name:</strong></label>
                    <label class="value col-lg-12">{{ name }}</label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Status:</strong></label>
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    <label [ngStyle]="changeStatusColor(userStatus)" class="m-2" id="statusLable">{{ displayValue}}</label>
                      <br> <br>
                    <label class="value col-lg-12" > {{ statusInfo }}</label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Country / State:</strong></label>
                    <label class="value col-lg-12">{{ country }} {{ countryCodeStr }}</label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Document Type</strong></label>
                    <label class="value col-lg-12">{{ documentType }}</label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Document Score</strong></label>
                    <label class="value col-lg-12">{{ documentScore}}</label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Face Score</strong></label>
                    <label class="value col-lg-12">{{ faceMatches | number : '1.2-2'}} % </label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Name Score</strong></label>
                    <label class="value col-lg-12">{{ nameScore}} </label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Date of Birth Score</strong></label>
                    <label class="value col-lg-12">{{ ageScore }}</label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Liveness&nbsp;</strong></label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="m-2" id="livenessLable" [ngStyle]="getLivenessColor()">
                      {{ livenessDTO }}
                    </label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>ID Liveness Score</strong></label>
                    <label class="value col-lg-12">{{ alternateTextScore }} </label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Risk Factor</strong></label>
                    <label class="value col-lg-12">{{ riskFactor }} </label>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>CPF</strong></label>
                    <label class="value col-lg-12">{{ customProperties }}
                    </label>
                  </div>
                </div> 

                <!-- <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Liveness&nbsp;</strong></label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="m-2" id="livenessLable" [ngStyle]="getLivenessColor()" >
                      {{ livenessDTO }}
                    </label>
                  </div>
                </div> -->

                <div class="col-lg-6 forgeryDiv" [hidden]="!forgeryDetection">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Forgery Status</strong></label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label [hidden]="!forgeryStatus" class="m-2" id="forgeryStatusLable" [ngStyle]="setLableColor()">
                      {{ forgeryStatus }}
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label class="value col-lg-12">{{ forgeryResult }}
                    </label>
                  </div>
                </div>

                <div class="col-lg-6 forgeryDiv">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Failure Reason</strong></label>
                    <label class="value col-lg-12">{{ failureReason }} </label>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Device Information</strong></label>
                    <label class="value col-lg-12">{{ userAgent }} </label>
                  </div>
                </div>

                <div class="col-lg-12" *ngIf="otherDetails"> 
                    <p></p>
                    <div class="form-group">
                      <label class="col-lg-12"><strong>Other Details</strong></label>
                    </div>
                </div>
                
                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_argentina || countryCode === countryCode_france || countryCode === countryCode_india)">
                    <div class="form-group">
                      <label class="col-lg-12"><strong>Address</strong></label>
                      <label class="value col-lg-12">{{ address }}
                      </label>
                    </div>
                </div>

                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_argentina)">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>CUIL</strong></label>
                    <label class="value col-lg-12">{{ cuil }}
                    </label>
                  </div>
                </div>

                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_ukrain)">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>RNTRC</strong></label>
                    <label class="value col-lg-12">{{ rntrc }}
                    </label>
                  </div>
                </div>
                <!--
                <div class="col-lg-6" *ngIf="otherDetails && countryCode === countryCode_ukrain">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Place Of Birth</strong></label>
                    <label class="value col-lg-12">{{ placeOfBirth }}
                    </label>
                  </div>
                </div>
                -->
                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_india)">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Aadhaar</strong></label>
                    <label class="value col-lg-12">{{ aadhaar }}
                    </label>
                  </div>
                </div>

                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_paraguay )">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Nationality</strong></label>
                    <label class="value col-lg-12">{{ nationality }}
                    </label>
                  </div>
                </div>

                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_paraguay)">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Date Of Issue</strong></label>
                    <label class="value col-lg-12">{{ dateOfIssue }}
                    </label>
                  </div>
                </div>

                <div class="col-lg-6" *ngIf="otherDetails && (countryCode === countryCode_paraguay)">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>IC</strong></label>
                    <label class="value col-lg-12">{{ ic }}
                    </label>
                  </div>
                </div>
                
                <div class="col-lg-12"  *ngIf="mrzCheckDigitResult">
                  <p></p>
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Passport Checksum Validation</strong></label>
                     <div class="col-lg-12" >
                      <!-- <div class="value col-lg-4"  *ngFor="let mrz of mrzCheckDigitResult | keyvalue"> -->

                        <!-- <div  *ngFor="let mrz of mrzCheckDigitResult | keyvalue"> 
                          <b>{{mrz.key}}</b> : {{mrz.value}}
                        </div> --> 
                        
                        <div><b>Doc Number: </b>{{docNum}}</div>
                        <div><b>DOB: </b>{{dob}}</div>
                        <div><b>DOE: </b> {{doe}}</div>
                        <div><b>Personal Number: </b> {{personalNum}}</div>
                        <div><b>Overall: </b> {{overall}}</div>
                    </div> 
                  </div>
                </div>

                <div class="col-lg-12" id="locationDiv" [hidden]="enableMap()">
                  <div class="form-group">
                    <label class="col-lg-12"><strong>Location&nbsp;</strong></label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <iframe width="500" height="200" [src]="mapURL | safe"></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="col-lg-12">
                <div class="raw">
                  <ng2-smart-table class="tbl-col dataTable" id="testTable" [settings]="tableSettings"
                    [source]="source">
                  </ng2-smart-table>
                </div>
              </div>

              <div class="col-lg-12" [hidden]="!enableEditView" id="editPanel">
                <div class="raw">
                  <div class="form-group detail-input-group col-lg-6">
                    <select class="form-control" id="selectStatus" [(ngModel)]="userStatus"
                      (change)="selectStatus($event)">
                      <option *ngFor="let item of statusList | keyvalue" [value]="item.value" [selected]="item.value">
                        {{ item.key }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group detail-input-group col-lg-6">
                    <button type="button" (click)="updateStatus()" class="btn btn-primary btn-lg">
                      Update Status
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-12" id="videoButton">
                <div class="raw">
                  <div class="form-group detail-input-group col-lg-6" [hidden]="!(userType == 'agent')">
                    <button type="button" (click)="updateVideoStatus()" class="btn btn-primary btn-lg">
                      {{ verifyStatus == "pending"? "Assign Video" : "Unassign Video" }}
                    </button>
                  </div>
                </div>
              </div> -->
              <div class="col-lg-12 imageSlider" [hidden]="!showScannedImages">
                <div>
                  <ng-image-slider class="image-slider" #nav [images]="imageHistory" [infinite]="false" [autoSlide]="1"
                    [imageSize]="{ width: 'auto', height: 'auto' }" slideImage="1"></ng-image-slider>
                  <Span>
                    <h5 style="margin-left: 30%" [hidden]="!fetchImageMessageShow">
                      Please wait, We are fetching the details.
                    </h5>
                  </Span>
                </div>
              </div>
              
              <div class="col-lg-12" [hidden]="!transform()" id="qrPanel">
                <div class="raw">
                  <div id="qrcodeDiv">
                    <img id="qrcode" [src]="transform()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </nb-card-body>
</nb-card>

<nb-card *ngIf="liveVideo === 'Live' && agentDetails && (displayValue === 'Success' || displayValue === 'Failed' || displayValue === 'failed')">
  <nb-card-body>
    <section>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">

                  <div class="form-group">
                      <label class="col-lg-12 title-margin-bottom">
                        <strong>
                          <h4>Agent's Verification Details </h4>
                        </strong>
                      </label>
                  </div>

                  <div class="col-lg-12" >
                    <div class="form-group">
                      <label><strong>Agent Name:</strong></label> <br>
                      <label class="value">{{ verifiedByAgentName }} </label>
                    </div>
                  </div>

                  <div class="col-lg-12 margin-bottom" >
                    <div class="form-group">
                      <label><strong>Agent's Comment</strong></label> <br>
                      <label class="value">{{ agentComment }} </label>
                    </div>
                  </div>

                  <div class="form-group square-jelly-box ">
                    <label class="col-lg-12"><strong><h4>Document Verification: </h4></strong></label>
                      <div class="col-lg-12">
                        <label class="value1">1. Can you confirm Your Name ?  </label>  &nbsp; <label class="value"> {{name}}  </label>  <br><label class="value text-uppercase">{{ agentVerifyName  }} </label><br>
                        <label class="value1">2. Did you selected this ID ? </label>  &nbsp; <label class="value"> {{documentType}} </label> <br><label class="value text-uppercase">{{ agentVerifyDoc }} </label><br>
                        <label class="value1">3. Is this Your DOB ? </label>  &nbsp; <label class="value"> {{inputdob}}</label> <br><label class="value text-uppercase">{{ agentVerifyDob }} </label><br>
                        <label class="value1">4. Are you residing in this Country ? </label>  &nbsp; <label class="value">{{country}} {{countryCode}} </label> <br><label class="value text-uppercase">{{ agentVerifyCountryState }} </label><br>
                      </div>
                  </div>

            </div>
            <div class="col-lg-6">  
                <div class="form-group" *ngIf="socialDetails">
                  <label class="title-margin-bottom">
                    <strong>
                      <h4> Social Result </h4>
                    </strong>
                  </label> 
                </div>
                <div class="col-lg-12" *ngIf="socialDetails">
                  <div>
                      <div class="col-lg-12">
                        <label>Risk Score:</label> &nbsp;&nbsp; <label ><h4 class="text-danger">{{riskScore}}</h4></label>
                      </div>
                      <div class="col-lg-12">
                        <label>Registered Profile </label>
                      </div>
                      <div class="col-lg-2" *ngFor="let data of registeredAccounts" style="margin: 1rem 1rem 1rem 1rem;" >
                          <img src="{{data.image}}" alt="" class="footer-image" style="width: 3rem;"/>
                      </div>
                      <div class="col-lg-12"  >
                        &nbsp;<label  *ngIf="registeredAccounts.length  == 0 " class="value">Not Found ! </label>
                      </div> 
                    </div>
                  </div>  

                <div class="form-group" *ngIf="showVideolist">
                  <label class="title-margin-top">
                    <strong>
                      <h4> Recorded Video</h4>
                    </strong>
                  </label> 
                </div>
                <div  *ngIf="showVideolist">
                  <div class="row"  *ngFor="let video of videoHistory">
                    <div class="col-lg-12 videos">
                      <video class="width:90%;height:90%;" onclick="this.play();arguments[0].preventDefault();" class="video-js video vjs-default-skin"  height="100%" width="100%" controls controlsList="nodownload" preload="none" data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }' poster="assets/img/videoPlaceholder.png">
                        <source src={{video.url}} type="video/mp4" />
                      </video> 
                    </div>
                  </div> 
                </div> 
              </div>
              </div> 
            </div>
        </div>
    </section>
  </nb-card-body>
</nb-card>

<!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">IDMLive</h5>
        <button type="button" class="close btn " (click)="closePopup()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <iframe [src]="videoURL" width="800" height="700" allow="camera;microphone"></iframe>

    </div>
  </div>

</div> -->

<nb-card [hidden]="!showOtherInfo" *ngIf="documentType === 'Passport'">
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-md-6">
            <h3 id="verification-title">
              Other Information:
            </h3>
          </div>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <section class="col-lg-12 connectedSortable">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="col-lg-4">
                <label><h4><u>MRZ Extractor Value</u></h4></label>
                  <div  *ngFor="let item of barcodeOutput | keyvalue" >
                    <b> {{(item.key === 'date_of_birth' || item.key === 'expiration_date') ?  item.key.toUpperCase() +' : (yyMMdd)  ' : item.key.toUpperCase()}} </b> :
                    {{(item.value === 'passp')? 'Passport' :  item.value}}
                  </div>
                </div>
                <div class="col-lg-4">
                  <label><h4><u>OCR Extractor Value</u></h4></label>
                    <div class="scrollDiv">
                      <div  *ngFor="let ocr of ocrParseOutput | keyvalue"   >
                          <b>{{ocr.key}}</b>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-4">
                    <label><h4><u>MRZ-OCR Comparison Result</u></h4></label>
                      <div  *ngFor="let mrz of mrzOcrComparisonResult | keyvalue">
                        <b>{{mrz.key}}</b> : {{mrz.value}}
                      </div>
                  </div>     -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </nb-card-body>
</nb-card>


<nb-card *ngIf="showEndUserVerificationHistory && showEnduserHistorySection">
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-md-6">
            <h3 id="verification-title">
              <label>Previous Verifications:</label>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </nb-card-header>
  
  <nb-card-body>
    <section class="col-lg-12">
      <div class="card-body"> 
        <div class="col-lg-12 scrollDiv" *ngIf="prevTransactionHistory != null && prevTransactionHistory.length > 0">
          <div class="container">                         
            <div class="row">
                <div class="col">
                    <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
                        <div class="timeline-step" *ngFor="let obj of prevTransactionHistory; index as i">
                            <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                <div class="inner-circle" (click)="openHistoryTransaction(obj)"></div>
                                <p class="h6 mt-3 mb-1">{{obj.requested_time}}</p>
                                <p class="h6 text-muted mb-0 mb-lg-0">{{obj.uuid}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12" *ngIf="prevTransactionHistory == null || prevTransactionHistory.length === 0">
          <ul class="history col-lg-12">
            <li><label><h5>No Data Found!</h5></label></li>
          </ul>
        </div>
      </div>
    </section>
  </nb-card-body>
</nb-card>