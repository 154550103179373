import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss']
})
export class EditLinkComponent implements OnInit, ViewCell {
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  renderValue: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.renderValue = String(this.value);
  }

  onAction() {
    
    if (this.renderValue == null || this.renderValue === '') {
      return false;
    } else {
      window.location.href = this.renderValue;
    }
  }
}
