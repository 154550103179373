import { Component, OnInit, ViewChild } from '@angular/core';
//import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { TransactionAllChartComponent } from '../transaction-all-chart/transaction-all-chart.component';
import { LocalDataSource } from 'ng2-smart-table';
import { DashboardService } from '../dashboard.service';
import { VerificationsService } from 'src/app/verifications/verifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusLabelComponent } from '../../verifications/shared-model/status-label/status-label.component';
import * as moment from 'moment';


@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit {

  totalTransactions: Number;
  successTransactions: Number;
  failedTransactions: Number;
  inProgressTransactions: Number;
  startedTransactions: Number;
  errorTransactions: Number;

  source: LocalDataSource = new LocalDataSource();

  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 10
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      identifier: {
        title: 'Transactions ID',
        type: 'String',
        width: '25%',
        filter: false
      },
      name: {
        title: 'Person Name',
        type: 'String',
        width: '25%',
        filter: false
      },
      status: {
        title: 'Status',
        type: 'custom',
        renderComponent: StatusLabelComponent,
        width: '25%',
        filter: false
      },
      requestedTime: {
        title: 'Date',
        type: 'String',
        width: '25%',
        filter: false
      }
    },
  };


  constructor(
    private dashboardService: DashboardService,
    private verificationsService: VerificationsService,
    private spinner: NgxSpinnerService
  ) {
    this.source = new LocalDataSource();
  }

  ngOnInit(): void {

    this.tableSettings = Object.assign({}, this.tableSettings);
    this.showTransactions();
    this.showTableData();
    // this.source.load(this.data);
  }

  onUserRowSelect(event): void {
    // this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  showStaticModal(rowData: any) {
  }

  payloadMapper(data) {
    let list = [];
    data.forEach(element => {
      const requestedTime = moment(element.requestedTime).format('MMMM Do YYYY, h:mm:ss a');
      let obj = { ...element, requestedTime: requestedTime }
      list.push(obj);
    });
    return list;
  }


  showTableData() {
    this.verificationsService.getVerificationsPerPage(0, 10)
      .subscribe(verifications => {
        const modifiedData = this.payloadMapper(verifications);
        this.source.load(modifiedData);
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });

  }


  showTransactions() {
    this.spinner.show();
    this.dashboardService.getTransactions().subscribe(transactions => {
      
      this.successTransactions = transactions.verified;
      this.totalTransactions = transactions.total;
      this.failedTransactions = transactions.failed;
      this.inProgressTransactions = transactions.inProgress;
      this.startedTransactions = transactions.started;
      this.errorTransactions = transactions.error
    },
      (error) => {
        this.spinner.hide();
      });
  }






}
