import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
