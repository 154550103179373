
import { NgModule, Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent implements OnInit {
  @Input() status;


  @Output() statusChanged = new EventEmitter();

  marked = '';

  pending = 'Pending'
  in_progress= 'In Progress'
  completed = 'Completed'
  expired = 'Expired'

  displayValue = ''


  constructor() { }

  ngOnInit() {
    this.changeValue(this.status);

  }


  changeValue(status) {

    if (status === 'completed') {
      this.displayValue = this.completed;
      return this.styles.completed;
    } else if (status === 'pending') {
      this.displayValue = this.pending;
      return this.styles.pending;
    } else if (status === 'in_progress') {
      this.displayValue = this.in_progress;
      return this.styles.in_progress;
    } else if (status === 'timed_out') {
      this.displayValue = this.expired;
      return this.styles.expired;
    } else {
      this.displayValue = this.in_progress;
      return this.styles.in_progress;
    }

  }


  styles = {
    pending: {
      'background-color': '#ffc107',
      'color': 'gray'
    },
    completed: {
      'background-color': '#28a745',
      'color': 'white'
    },
    in_progress:{
      'background-color': '#807d7d',
      'color': 'white'
    },
    expired:{
      'background-color': '#807d7d',
      'color': 'white'
    }
  }


}
