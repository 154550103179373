import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../user.service';
import { User } from 'src/app/model/user.model';
import { FormGroup } from '@angular/forms';
import { AlertService } from '../../shared/_alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/app/configuration/configuration.service';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  userTypeList: any = {
    Admin: 'tenant_admin',
    User: 'tenant_user',
    Agent: 'agent',
  };

  form: FormGroup;
  id: number;
  firstName: String;
  lastName: String;
  password: String;
  phone: String;
  email: String;
  userType: String;
  image: String;
  editMode = false;
  showImage = false;
  totalAttempt: number = 0;

  // Tenant's configurations
  doBVerification: Boolean;
  skipLiveness: Boolean;
  forgeryDetection: Boolean;
  skipInputName: Boolean;
  showScannedImages: Boolean;
  hideFooter: Boolean;
  enableCountryList: Boolean;
  enableLiveVideo: Boolean;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.getTenantConfiguration();
      this.editMode = params['id'] != null;
      this.initForm();
    });
  }

  private initForm() {
    if (this.editMode) {
      this.spinner.show();
      this.userService.getUserById(this.id).subscribe((user: User) => {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.email = user.email;
        this.phone = user.phone;
        this.userType = user.userType;
        this.totalAttempt = user.totalAttempt;
        this.imageSrc = user.image;
        if (this.imageSrc !== null) {
          this.showImage = true;
        }
        this.spinner.hide();
      });
    }
  }

  disableField() {
    if (this.editMode) {
      return true;
    }
    return false;
  }

  onBack() {
    this.router.navigate(['user']);
  }

  alphaNumberOnly(e) {
    // Accept only alpha numerics, not special characters
    var regex = new RegExp('^[a-zA-Z0-9 ]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  specialCharNumberOnly(e) {
    // Accept only alpha numerics, not special characters
    var regex = new RegExp('^[0-9+]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  onSave() {
    let isSuccess = true;

    if (
      this.firstName === undefined ||
      this.firstName.trim() === '' ||
      this.firstName === null
    ) {
      isSuccess = false;
      this.alertService.error('First Name is required.', this.options);
    }
    if (
      this.lastName === undefined ||
      this.lastName.trim() === '' ||
      this.lastName === null
    ) {
      isSuccess = false;
      this.alertService.error('Last Name is required.', this.options);
    }
    if (this.userType === undefined) {
      isSuccess = false;
      this.alertService.error('Please Select User Type.', this.options);
    }
    if (
      this.email === undefined ||
      this.email.trim() === '' ||
      this.email === null
    ) {
      isSuccess = false;
      this.alertService.error('Email is required.', this.options);
    }
    if (
      this.phone === undefined ||
      this.phone.trim() === '' ||
      this.phone === null
    ) {
      isSuccess = false;
      this.alertService.error('Phone is Required.', this.options);
    }

    if (isSuccess) {
      this.spinner.show();
      if (!this.editMode) {
        if (
          this.firstName === '' ||
          this.firstName === null ||
          this.firstName === undefined
        ) {
          this.alertService.success('First Name is Required.', this.options);
        }
        const user = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          userType: this.userType,
          image: this.imageSrc,
          totalAttempt: this.totalAttempt,
        };
        this.userService.createUser(user).subscribe(
          (result) => {
            this.alertService.success('User added Sucessfully', this.options);
            this.spinner.hide();
            this.onBack();
          },
          (e) => {
            this.spinner.hide();
            this.alertService.error(e.error.errorMessage, this.options);
          }
        );
      } else {
        const edituser = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          userType: this.userType,
          image: this.imageSrc,
          id: this.id,
          totalAttempt: this.totalAttempt,
        };
        this.userService.editUser(edituser).subscribe(
          (result) => {
            this.alertService.success(
              'User has changed Sucessfully',
              this.options
            );
            this.spinner.hide();
            this.onBack();
          },
          (e) => {
            this.spinner.hide();
            this.alertService.error(e.error.errorMessage, this.options);
          }
        );
      }
    }
  }

  selectUserType(event) {
    this.userType = event.target.value;
  }

  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var pattern = /image-*/;
    var reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
    this.showImage = true;
  }

  cancel() {
    this.imageSrc = '';
    this.showImage = false;
    this.loaded = false;
    this.imageLoaded = false;
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  getTenantConfiguration() {
    const api = this.configurationService.getConfiguration();
    api.subscribe(
      (tenantDetail) => {
        this.doBVerification = tenantDetail.doBVerification;
        this.skipLiveness = tenantDetail.skipLiveness;
        this.forgeryDetection = tenantDetail.forgeryDetection;
        this.skipInputName = tenantDetail.skipInputName;
        this.showScannedImages = tenantDetail.showScannedImages || null;
        this.hideFooter = tenantDetail.hideFooter || null;
        this.enableCountryList = tenantDetail.enableCountryList || null;
        this.enableLiveVideo = tenantDetail.enableLiveVideo;
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
