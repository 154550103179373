import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
//import { ChartsModule } from 'ng2-charts';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { NgApexchartsModule } from "ng-apexcharts"
import { BrowserModule } from "@angular/platform-browser";
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TransactionAllChartComponent } from './transaction-all-chart/transaction-all-chart.component';
import { TransactionSmsChartComponent } from './transaction-sms-chart/transaction-sms-chart.component';
import {DashboardComponent} from './dashboard.component';
import { AppRoutingModule } from '../app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';




@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NbCardModule,
    Ng2SmartTableModule,
    NgApexchartsModule,
    AppRoutingModule,
    NgxSpinnerModule
  ],
  declarations: [DashboardComponent, DashboardViewComponent, TransactionAllChartComponent, TransactionSmsChartComponent]
})
export class DashboardModule { }
