import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from 'src/app/profile/profile.service';
import { AlertService } from 'src/app/shared/_alert';
import { VerificationsService } from 'src/app/verifications/verifications.service';
import { environment } from 'src/environments/environment';


export class SocialAccount {
  name: string;
  image: string; 
}

@Component({
  selector: 'app-video-verification',
  templateUrl: './video-verification.component.html',
  styleUrls: ['./video-verification.component.scss']
})
export class VideoVerificationComponent implements OnInit {

  @Input() public event; 
  liveUrl = environment.liveUrl;

  form: FormGroup;
  displayStyle = "none";
  displayTab1 = "block";
  displayTab2 = "none";
  displayTab3 = "none";
  displayTab4 = "none";
  displayTab5 = "none";
  bgGreencolor = '#0b6c1c';
  bgGraycolor = '#ccc';
  videoURL : SafeResourceUrl;

  qrCode: string;
  name: String;
  identifier: String;
  documentType: String;
  documentScore: String;
  country: String;
  riskFactor: String;
  faceMatches: Number;
  livenessDTO: any;
  nameScore: String;
  longitude: String;
  latitude: String;
  userAgent: String;
  countryCode: String;
  forgeryStatus: any;
  forgeryResult: String;
  failureReason: String;
  statusInfo: String;
  timeStamp: String;
  imageHistory = [];
  faceImageHistory = [];
  IdImageHistory = [];
  alternateTextScore: String;
  customProperties: any;
  forgeryDetection: any;
  verifyStatus: string;
  email:string;
  token:string;
  liveVideo: string;
  showScannedImages = false;
  inProgress = 'Pending';
  expired = 'Expired';
  success = 'Success';
  error = 'Error';
  failed = 'Failed';
  started = 'Started';
  Live = 'Live';
  Scan = 'Scan';
  userType = '';
  tenantId = '';
  pageOffset = 0;
  queueId = 0;
  agentName = '';
  dobScore = '';
  mobile: String;
  tenantName: String;
  skipLiveness: boolean;
  dob;

  isQue1: boolean = false;
  isQue2: boolean = false;
  isQue3: boolean = false;
  isQue4: boolean = false;
  agentComment: string;
  agentStatus: boolean;
  // btnHidden: boolean;
  // disableInputbox =  false;
  btnVerifyDisable: boolean =  false;
  socialDetails;
  number: string;
  riskScore: number;
  accountDetails:any;
  registeredAccounts: Array<SocialAccount> = [];
  isSubmitted: boolean;
  interval;
  processName: string = '';

  constructor(private sanitizer: DomSanitizer,
    private verificationsService: VerificationsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    public profileService: ProfileService,
    public alertService: AlertService) { }


  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
    alert(control.value);
    if((control.value as string).indexOf(' ') >= 0){  
        return {cannotContainSpace: true}  
    } 
  }

  ngOnInit(): void {

    this.form = new FormGroup({
      isQue1: new FormControl(''),
      isQue2: new FormControl(''),
      isQue3: new FormControl(''),
      isQue4: new FormControl(''),
      agentComment: new FormControl('', [Validators.required]) 
    });

    const user = this.profileService.getUserDetail();
    this.userType = user.userType;
    this.agentName = user.firstName + " " + user.lastName;
    this.email = user.email; 
    document.getElementById('tab1').classList.add('is-active'); 
    
    this.getDetail(this.event.data.identifier);

    this.interval = setInterval(() => { 
        if(this.isSubmitted){
        this.verificationsService.getVideoVerificationStatusById(this.event.data.identifier).subscribe(
          (result) => {
            this.verifyStatus = result.status;
            if(this.verifyStatus === 'completed' && this.isSubmitted) {
              this.closePopup();
              setTimeout(() => this.updateAgentFeedbackStatus(this.event.data.identifier),4000);              
            }
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
          });
      }
    }, 3000);
  }
 
  get f() { return this.form.controls; }

  getDetail(identifier) {
      this.spinner.show(); 
      this.verificationsService.getVerificationsById(identifier).subscribe(
        (verifications) => {
          this.initDetail(verifications);
          this.view();
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        });
  }
   
  initDetail(verifications) {
      this.identifier = verifications.identifier;
      this.name = verifications.name;
      this.documentType = verifications.documentType || 'unknown';
      this.documentScore = verifications.documentScore === 'N/A' ? 'N/A' : (verifications.documentScore || 0) + ' %';
      this.country = verifications.country;
      this.countryCode = verifications.countryCode != null ? '(' + verifications.countryCode + ')' : '';
      this.riskFactor = verifications.riskFactor === 'N/A' ? 'N/A' : (verifications.riskFactor || 0) + ' %';
      this.nameScore = verifications.nameScore || 0;
      this.faceMatches = verifications.faceMatches != null && verifications.faceMatches.length > 0 ? (Math.floor(verifications.faceMatches[0] * 100)/100) : 0;
      this.livenessDTO = verifications.livenessDTO == null || verifications.livenessDTO.live === 'SKIPPED' ? 'N/A' : this.getLiveness(verifications.livenessDTO);
      this.longitude = verifications.longitude || null;
      this.latitude = verifications.latitude || null;
      this.userAgent = verifications.userAgent;
      this.qrCode = verifications.qrCode || '';
      this.mobile = verifications.mobile == null ? this.disabledVerifyButton() : verifications.mobile ;
      this.skipLiveness = verifications.skipLiveness;
      this.dobScore = verifications.dobScore == null || verifications.dobScore === 'N/A' ? 'N/A' : verifications.dobScore + ' %';
      this.tenantName = verifications.tenantName;
      this.dob = (verifications.dateOfBirth == null || verifications.dateOfBirth === '' || verifications.dateOfBirth === 'N/A') ? '-' : verifications.dateOfBirth ; 

      // this.userStatus = verifications.status;
      // this.statusInfo = verifications.statusInfo || '';
      this.token = verifications.token || '';
      // this.ageScore =
      //   verifications.dobScore == null || verifications.dobScore === 'N/A'
      //     ? 'N/A'
      //     : verifications.dobScore + ' %';
      // this.forgeryStatus = this.getForgeryStatus(verifications.forgeryStatus);
      // this.forgeryResult = verifications.forgeryResult || '';
      // this.failureReason = verifications.failureReason || 'N/A';
      // this.timeStamp = moment(verifications.requestedTime).format(
      //   'MMMM Do YYYY, h:mm:ss a'
      // );
      this.showScannedImages = verifications.showScannedImages;
      // this.alternateTextScore =
      //   verifications.alternateTextScore === 'N/A'
      //     ? 'N/A'
      //     : (verifications.alternateTextScore || 0) + ' %';
      // if (verifications.validations != null) {
      //   this.source.load(verifications.validations);
      // }
      // this.changeColor(this.livenessDTO);
      // this.changeStatusColor(this.userStatus);
      if (this.showScannedImages) {
        this.imageHistory = this.imageMapper(verifications.imageHistory);
        this.faceImageHistory = this.faceImageMapper(verifications.imageHistory);
        this.IdImageHistory = this.idImageMapper(verifications.imageHistory);
      }
      // this.customProperties =
      //   verifications.customProperties == null
      //     ? 'N/A'
      //     : this.getCustomProperties(verifications.customProperties);
  
      this.liveVideo = (verifications.liveVideo === true ? "Live" : "Scan");
    }

    view() {
      this.verifyStatus = this.event.data.status;
      this.queueId = this.event.data.id; 
  
      if (this.verifyStatus === 'Pending' || this.verifyStatus === 'pending') {
        this.verificationsService.assignVideoCall(this.queueId, this.email).subscribe(
          (result) => {
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.liveUrl+'/room/' + this.event.data.identifier + '/Agent/' + this.token + '/' + environment.baseUrl.replace("https://", ""));
            this.verifyStatus = 'in_progress';
            this.spinner.hide();
          },
          (e) => {
            this.spinner.hide();
            this.alertService.error(e.error.errorMessage, this.options);
          }
        ); 
      } else if (this.verifyStatus === 'in_progress') {

        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.liveUrl+'/room/' + this.event.data.identifier + '/Agent/' + this.token + '/' + environment.baseUrl.replace("https://", ""));
        this.spinner.hide(); 
        this.verifyStatus = 'in_progress';


        // this.verificationsService.unAssignVideoCall(this.queueId).subscribe(
        //   (result) => {
        //     this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.liveUrl+'/room/' + this.event.data.identifier + '/Agent/' + this.token + '/' + environment.baseUrl.replace("https://", ""));
          
        //     // this.alertService.success(
        //     //   'Video status has updated successfully',
        //     //   this.options
        //     // );
        //     this.spinner.hide(); 
           
        //     // this.verifyStatus = 'pending';
        //   this.verifyStatus = 'in_progress';
            
        //   },
        //   (e) => {
        //     this.spinner.hide();
        //     this.alertService.error(e.error.errorMessage, this.options);
        //   }
        // );
      }
    }
  
    closePopup() {
        this.activeModal.close();
    }
    
    disabledVerifyButton() {
      this.btnVerifyDisable = true;
    }

    getLiveness(liveness) {
      if (liveness && liveness.live) {
        return liveness.live === 'REAL' ? true : false;
      }
      return false;
    }

    getLivenessColor() {
      if (typeof this.livenessDTO == 'boolean' && !this.livenessDTO) {
        return { 'background-color': 'red' };
      } else if (typeof this.livenessDTO == 'boolean' && this.livenessDTO) {
        return { 'background-color': 'green' };
      } else {
        return {
          'background-color': 'none',
          color: '#696969',
          'padding-left': '0',
        };
      }
    }
  

    // imageMapper(data) {
    //   let list = [];
    //   data.forEach((element) => {
    //     let obj = {
    //       image: element.url,
    //       thumbImage: element.url,
    //       title: moment(element.uploadedTime).format('MMMM Do YYYY, h:mm:ss a'),
    //     };
    //     list.push(obj);
    //   });
    //   return list;
    // }

    imageMapper(data) {
      let list = [];
      data.forEach((element) => {
        console.info(JSON.stringify(element));
        if (element.processName != null && element.processName !== 'undefined') {
          this.processName = ((element.processName != undefined && element.processName === 'face') ? 'Face' :
            (element.processName === 'input') ? "Id Card" :
              (element.processName === 'input_back') ? "Id Card Back" :
                (element.processName === 'video') ? "Video" : '');
        }

        var title = moment(element.uploadedTime).format('MMMM Do YYYY, h:mm:ss a') + '\xa0\xa0\xa0\xa0\xa0\xa0' + this.processName.toLocaleUpperCase();

        let obj = {
          image: element.url,
          thumbImage: element.url,
          title: title
        }; 
        
        list.push(obj);
      });
      return list;
    }

    faceImageMapper(data) {
      let list = [];
      data.forEach((element) => {
        console.info(JSON.stringify(element));
        if (element.processName != null && element.processName !== 'undefined') {
          this.processName = ((element.processName != undefined && element.processName === 'face') ? 'Face' :
            (element.processName === 'input') ? "Id Card" :
              (element.processName === 'input_back') ? "Id Card Back" :
                (element.processName === 'video') ? "Video" : '');
        }

        var title = moment(element.uploadedTime).format('MMMM Do YYYY, h:mm:ss a') + '\xa0\xa0\xa0\xa0\xa0\xa0' + this.processName.toLocaleUpperCase();

        let obj = {
          image: element.url,
          thumbImage: element.url,
          title: title
        }; 
        if(this.processName === 'Face') {
          list.push(obj);
        }
      });
      return list;
    }

    idImageMapper(data) {
      let list = [];
      data.forEach((element) => {
        console.info(JSON.stringify(element));
        if (element.processName != null && element.processName !== 'undefined') {
          this.processName = ((element.processName != undefined && element.processName === 'face') ? 'Face' :
            (element.processName === 'input') ? "Id Card" :
              (element.processName === 'input_back') ? "Id Card Back" :
                (element.processName === 'video') ? "Video" : '');
        }

        var title = moment(element.uploadedTime).format('MMMM Do YYYY, h:mm:ss a') + '\xa0\xa0\xa0\xa0\xa0\xa0' + this.processName.toLocaleUpperCase();

        let obj = {
          image: element.url,
          thumbImage: element.url,
          title: title
        }; 
        if(this.processName != 'Face') {
          list.push(obj);
        }
      });
      return list;
    }

    onNext(tab) {
      if(tab === 'tab1') {
        document.getElementById('tab1').classList.add('is-active'); 

        if(!this.skipLiveness){
          document.getElementById('tab2').classList.remove('is-active');
        }

        document.getElementById('tab3').classList.remove('is-active');

        if(this.mobile){
          document.getElementById('tab4').classList.remove('is-active');
        }
        
        document.getElementById('tab5').classList.remove('is-active');

        this.displayTab1 = "block";
        this.displayTab2 = "none";
        this.displayTab3 = "none";
        this.displayTab4 = "none";
        this.displayTab5 = "none";
      }
      if(tab === 'tab2') {
        document.getElementById('tab1').classList.remove('is-active'); 

        if(!this.skipLiveness){
          document.getElementById('tab2').classList.add('is-active');
        }
        
        document.getElementById('tab3').classList.remove('is-active');

        if(this.mobile){
          document.getElementById('tab4').classList.remove('is-active');
        }
        
        document.getElementById('tab5').classList.remove('is-active');

        this.displayTab1 = "none";
        this.displayTab2 = "block";
        this.displayTab3 = "none";
        this.displayTab4 = "none";
        this.displayTab5 = "none";
      }
      if(tab === 'tab3') {
        document.getElementById('tab1').classList.remove('is-active'); 

        if(!this.skipLiveness){
          document.getElementById('tab2').classList.remove('is-active');
        }
        
        document.getElementById('tab3').classList.add('is-active');

        if(this.mobile){
          document.getElementById('tab4').classList.remove('is-active');
        }
        
        document.getElementById('tab5').classList.remove('is-active');
        
        this.displayTab1 = "none";
        this.displayTab2 = "none";
        this.displayTab3 = "block";
        this.displayTab4 = "none";
        this.displayTab5 = "none"; 
      }
      if(tab === 'tab4') {
        document.getElementById('tab1').classList.remove('is-active'); 

        if(!this.skipLiveness){
          document.getElementById('tab2').classList.remove('is-active');
        }
        
        document.getElementById('tab3').classList.remove('is-active');

        if(this.mobile){
          document.getElementById('tab4').classList.add('is-active');
        }
        
        document.getElementById('tab5').classList.remove('is-active');

        this.displayTab1 = "none";
        this.displayTab2 = "none";
        this.displayTab3 = "none";
        this.displayTab4 = "block";        
        this.displayTab5 = "none";
      }
      if(tab === 'tab5') {
        document.getElementById('tab1').classList.remove('is-active'); 

        if(!this.skipLiveness){
          document.getElementById('tab2').classList.remove('is-active');
        }
        
        document.getElementById('tab3').classList.remove('is-active');

        if(this.mobile){
          document.getElementById('tab4').classList.remove('is-active');
        }
        
        document.getElementById('tab5').classList.add('is-active');         

        this.displayTab1 = "none";
        this.displayTab2 = "none";
        this.displayTab3 = "none";
        this.displayTab4 = "none";
        this.displayTab5 = "block"; 
      }
    }

    onSubmit(status) {
      // if(this.form.get('agentComment').value.trim.length === 0){
      //   this.form.controls['agentComment'].setErrors({'incorrect': true});  
      //   this.agentComment='';
      // } 

      if (this.form.invalid) { 
        this.form.get('agentComment').markAsTouched();
        return;
      } else { 
        this.isSubmitted = true;
        this.btnVerifyDisable = true;
        // this.btnHidden = true;
        // this.disableInputbox = true;

        this.form.controls['isQue1'].disable();
        this.form.controls['isQue2'].disable();
        this.form.controls['isQue3'].disable();
        this.form.controls['isQue4'].disable();
        this.form.controls['agentComment'].disable(); 
      }

      const body = {
        verifyName: this.isQue1,
        verifyDoc: this.isQue2,
        verifyDob: this.isQue3,
        verifyCountryState: this.isQue4,
        comment: this.agentComment,
        status: ((status != null && status === 'approved')? 'verified' : 'failed')
      };

      this.updateVerificationResultByAgent(body);
    }

    updateVerificationResultByAgent(body) {
      this.spinner.show();
 
      if (this.userType == 'agent') {
        this.verificationsService
          .updateVerificationResultByAgent(this.identifier, body)
          .subscribe((result) => {
              this.alertService.success(`Agent's feedback has been updated successfully.`,this.options);
              this.spinner.hide();
            },
            (e) => {
              this.spinner.hide();
              this.alertService.error(e.error.errorMessage, this.options);
            }
          );
      }
    }

    verifyByMobile() { 
      // this.spinner.show();
      if (this.userType === 'agent') { 
        const body = {
          request_id: "IDMLIVE",
          phone: this.mobile
        };

        this.verificationsService
          .verifySocial(this.identifier, body)
          .subscribe((result) => {
              this.iterateSocialDetails(result);
              this.btnVerifyDisable = true;
              this.spinner.hide();
            },
            (e) => {
              this.spinner.hide();
              this.alertService.error(e.error.errorMessage, this.options);
            }
          );
      }
    } 

    iterateSocialDetails(result) {
      this.socialDetails = result ;
      this.registeredAccounts = [];

      const res = (result);
      Object.entries(res).forEach(([key1, value1], index) => { 
        Object.entries(value1).forEach(([key2, value2], index) => {
          if(key2 === 'number') {
            this.number = value2;
          }

          if(key2 === 'risk_score') {
            this.riskScore = value2;
          }
        
          if(key2 === 'account_details') {
            Object.entries(value2).forEach(([key3, value3], index) => { 
              Object.entries(value3).forEach(([key4, value4], index) => {
                if(key4 === 'registered' && value4 === true) { 
                  let customObj = new SocialAccount();
                  customObj.name = key3;
                  customObj.image = `assets/social-img/${key3}`+`.png`;
                  this.registeredAccounts.push(customObj);  
                }
              });
            });
          } 
        });
      }); 
      return res;
    }

    updateAgentFeedbackStatus(identifier){
      this.verificationsService
      .updateVerificationStatusAsAgentFeedbackStatus(identifier)
      .subscribe(
        (result) => {
          this.alertService.success(`Verification status has been updated successfully.`,this.options);
          this.spinner.hide(); 
        },
        (e) => {
          this.spinner.hide();
          this.alertService.error(e.error.errorMessage, this.options);
        }
      );
    }

    ngOnDestroy() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    } 

}
