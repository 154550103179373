import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalDataSource } from 'ng2-smart-table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/shared/_alert';
import { VerificationsService } from 'src/app/verifications/verifications.service';
import { RequestDTO } from 'src/app/reports/requestDTO.model';
import { ExportService } from '../export.service';

@Component({
  selector: 'app-verification-details-report',
  templateUrl: './verification-details-report.component.html',
  styleUrls: ['./verification-details-report.component.scss']
})
export class VerificationDetailsReportComponent implements OnInit {

  source: LocalDataSource = new LocalDataSource();
  form: FormGroup;
  fromDate = '';
  toDate = '';
  maxDate = new Date();
  searchValue = '';
  searchMode = false;
  isValidDate: any;
  pageOffset = 0;
  dataLimit = 15;
  status: string;
  date: string;
  verificationDetailsReport: any[];
  objDetail: any[];
  request: RequestDTO = new RequestDTO();

  constructor( public alertService: AlertService,
    private verificationService: VerificationsService,
    private spinner: NgxSpinnerService,
    private exportService: ExportService
    ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      // serverName: new FormControl('', [Validators.required]),
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]) 
      // status: new FormControl(''),
      // timezone: new FormControl('')
    });
  }

  validateDates(fromDate, toDate) {
    this.isValidDate = true;
    if ((fromDate == null || toDate == null || fromDate == '' || toDate == '')) {
      this.alertService.error('From date and To date are required.', this.options);
      this.isValidDate = false;
    } else if ((fromDate != null && toDate != null) && (toDate) < (fromDate)) {
      this.alertService.error('To date should be grater then From date.', this.options);
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  onSubmit() { 
    this.spinner.show();
    const validateRes = this.validateDates(this.fromDate,this.toDate);
    const requestObject = {
      "fromDate": this.fromDate,
      "toDate": this.toDate 
    }; 

    if(validateRes){
      this.searchVerificationByDateRange(requestObject);
    }
  }

  searchVerificationByDateRange(requestObject) { 
    this.source.empty();
  // this.disableNxtButton = true; 
    this.verificationService.getVerificationDetails(requestObject).subscribe(data => {
      this.objDetail = data; 
      const tempObj = [];
      for (let i = 0; i < this.objDetail.length; i++) {
        var reportObj = {
          "Transaction_Id": this.objDetail[i].identifier, 
          "Requested_Time": this.objDetail[i].requestedTime,
          "Country_Name": this.objDetail[i].country,
          "Document_Type": this.objDetail[i].documentType,         
          "Document_Score": this.objDetail[i].documentScore,
          "Name_Score": this.objDetail[i].nameScore,
          "DOB_Score": this.objDetail[i].dobScore,
          "Face_Match_Score": this.objDetail[i].faceMatches != null && this.objDetail[i].faceMatches.length > 0  ? this.objDetail[i].faceMatches[0] : 0,
          "Liveness":  this.objDetail[i].livenessDTO == null || this.objDetail[i].livenessDTO.live === 'SKIPPED' ? 'N/A' : this.getLiveness(this.objDetail[i].livenessDTO),
          "Risk_Factor": this.objDetail[i].riskFactor, 
          "Status": this.objDetail[i].status,
          "Transaction_Failure_Reason": (this.objDetail[i].failureReason !== null || this.objDetail[i].failureReason !== '') ? this.objDetail[i].failureReason : '-'

          // "Tenant_Name": this.objDetail[i].tenantName,          
          // "Name": this.objDetail[i].name, 
          // "Mobile": this.objDetail[i].mobile,
          // "DOB": this.objDetail[i].dob,          
          // "Country_Code": this.objDetail[i].country_code,           
          // "Barcode_Type": this.objDetail[i].barcodeType, 
          // "Id_Liveness_Score": this.objDetail[i].alternateTextScore,
          // "cpf": this.objDetail[i].customProperties == null ? 'N/A': this.getCustomProperties(this.objDetail[i].customProperties),          
          // "Latitude": this.objDetail[i].latitude,
          // "Longitude": this.objDetail[i].longitude,          
          // "QRCode": this.objDetail[i].qrCode,           
          // "Status_Info": this.objDetail[i].statusInfo,
          // "User_Agent": this.objDetail[i].userAgent,          
          // "Passport_Checksum_Validation" : this.objDetail[i].mrzCheckDigitResult, 


          // "ocrOutput": JSON.stringify(this.objDetail[i].ocrOutput).length <= 32766 ? (this.objDetail[i].ocrOutput) : 'object is too long ',
          // "barcodeOutput": JSON.stringify(this.objDetail[i].barcodeOutput).length <= 32766 ? (this.objDetail[i].barcodeOutput) : 'object is too long ',
          // "finalResults": JSON.stringify(this.objDetail[i].finalResults).length <= 32766 ? (this.objDetail[i].finalResults) : 'object is too long ',
          // "faceMatchOutput": JSON.stringify(this.objDetail[i].faceMatchOutput).length <= 32766 ? (this.objDetail[i].faceMatchOutput) : 'object is too long ',
          // "nameResults": this.objDetail[i].nameResults,
          // "forgeryResult": this.objDetail[i].forgeryResult,
          // "forgeryStatus": this.objDetail[i].forgeryStatus,
          // "validatedTime": this.objDetail[i].validatedTime  
           
        }
        tempObj.push(reportObj);
      }

      this.verificationDetailsReport = tempObj; 
      this.exportToCSV(this.verificationDetailsReport); 

    },(error) => {
      this.spinner.hide();
      console.log(error)
    });
  }
  
  getLiveness(liveness) {
    if (liveness && liveness.live) {
      return liveness.live === 'REAL' ? true : false;
    }
    return false;
  }

  getCustomProperties(customProperties) {
    if (customProperties && customProperties.CPF) {
      return customProperties.CPF;
    }
    return null;
  }

  options(arg0: string, options: any) {
    throw new Error('Method not implemented.');
  }
 
  disableDate() {
    return false;
  }

  exportToCSV(result){ 
    this.date = (new Date).getFullYear() + '-' + ((new Date).getMonth() + 1) + '-' + (new Date).getUTCDate() + 'at' + (new Date).getHours() + ':' + (new Date).getMinutes() + ':' + (new Date).getMilliseconds();
    this.exportService.exportAsCSVFile(result,`VERIFICATION_DETAILS_REPORT_FROM_${this.request.fromDate}_TO_${this.request.toDate}_ON_${this.date}`);
  }
 
}
