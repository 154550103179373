
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="row" id="headerBar">
        <h1 id="pageTopic" >&nbsp;&nbsp;&nbsp;User Information</h1>
        
      </div>

      <br>
      <br>
      <br>
      <br>
      <br>
      <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
            <button type="button" (click)="onCreate()" class="btn btn-primary btn-lg">Create New User</button>
        </div>
          </div>
        
      </div><!-- /.container-fluid -->
    </div>

</div>

<br>

<nb-card>

    <nb-card-body>
      <ngx-spinner bdColor = "rgb(51, 153, 255,0.1)" size = "medium" color = "#0073e6" type = "square-jelly-box" [fullScreen] = "true" size="medium">
        <p class="loading">Loading....</p>
      </ngx-spinner>
        <ng2-smart-table class="tbl-col dataTable" id='testTable'  [settings]="tableSettings" [source]="source"
        (edit)="onEdit($event)" (delete)="showStaticModal($event)" (userRowSelect)="onUserRowSelect($event)">
        </ng2-smart-table>
        <div class="container-fluid">
          <div class="row justify-content">
              <div class="col-md-offset-9 col-md-1">
              <button type="button"  [disabled]='disablePrevButton || pageOffset==0 ' (click)="onBack()" class="btn btn-primary btn-lg paginate-button">Previous</button>
          </div>
          <div class="col-md-1">
            <button type="button"  [disabled]='disableNxtButton || dataSize<15' (click)="onNext()" class="btn btn-primary btn-lg paginate-button">Next</button>
        </div>
            </div>
          
        </div>
    </nb-card-body>
  </nb-card>
