import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute ,Params} from '@angular/router';
import { MetaService } from '../meta.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertService } from '../../shared/_alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-metadata-upload',
  templateUrl: './metadata-upload.component.html',
  styleUrls: ['./metadata-upload.component.scss']
})
export class MetadataUploadComponent implements OnInit {

  metadataFile : File =  null;
  form: FormGroup;
  CSVfile: File ;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    public fb: FormBuilder
  ) { 
    this.form = this.fb.group({
      CSVfile:  ['']
    });
  }

  ngOnInit(): void {
  }

  submitForm(){
    this.spinner.show();
    console.log(this.metadataFile);
    const formData : any = new FormData();
    formData.append('file', this.form.get('CSVfile').value);
    this.metaService.uploadFile(formData).subscribe((result) => {
      this.alertService.success("Metadata file uploaded Sucessfully", this.options)
      this.spinner.hide();
      this.onBack()
    }, (e) => {
      this.spinner.hide();
      this.alertService.error(e.error.errorMessage, this.options)
    });;
  }

  onBack() {
    this.router.navigate(['metadata']);
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.metadataFile = file;
      this.form.get('CSVfile').setValue(file);
    }
  }

}
