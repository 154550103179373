import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentComponent } from './agent.component';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AppRoutingModule } from '../app-routing.module';
import { NbIconModule, NbSelectModule} from '@nebular/theme';
import { NgxSpinnerModule } from 'ngx-spinner';
import {AlertModule} from '../shared/_alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgImageSliderModule } from 'ng-image-slider';
import { VideoListComponent } from './video-list/video-list.component';
import {LabelValueComponent } from './label-value/label-value.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { VideoVerificationComponent } from './video-verification/video-verification.component'





@NgModule({
  declarations: [
    AgentComponent,
   VideoListComponent,
   LabelValueComponent,
   StatusLabelComponent,
   VideoVerificationComponent,
  ],
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    AppRoutingModule,
    NbToggleModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NbIconModule,
    NbSelectModule,
    AlertModule,
    NgbModule ,
    NgxSpinnerModule,
    NgSelectModule,
    NgImageSliderModule
  ]
})
export class AgentModule { }
