<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Create/Edit a User</h1>
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
  <nb-card-header>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Enter User Details</h1>
          </div>
        </div>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body>
    <div class="container" id="user-form">

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="name">First Name:<span class="required-asterisk">*</span> </label>
          <input type="text" class="col-md-10 form-control" id="firstName" placeholder="First Name"
            [(ngModel)]="firstName" (input)="alphaNumberOnly($event)" (keypress)="alphaNumberOnly($event)"
            (paste)="onPaste($event)">
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="lastName">Last Name:<span class="required-asterisk">*</span>
          </label>
          <input type="text" class="col-md-10 form-control" id="lastName" placeholder="Last Name" [(ngModel)]="lastName"
            (input)="alphaNumberOnly($event)" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)">
        </div>
      </div>

      <br>

      <div class="row" *ngIf="enableLiveVideo">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="userType">User Type:<span class="required-asterisk">*</span>
          </label>
          <select class="col-md-3 form-control" [(ngModel)]="userType" (change)="selectUserType($event)">
            <option *ngFor="let item of userTypeList | keyvalue" [value]="item.value" [selected]="item.value">
              {{item.key}}
            </option>
          </select>
        </div>
      </div>

      <div class="row" *ngIf="!enableLiveVideo">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="userType">User Type:<span class="required-asterisk">*</span>
          </label>
          <select class="col-md-3 form-control" [(ngModel)]="userType" (change)="selectUserType($event)">
            <option *ngFor="let item of userTypeList | keyvalue" [value]="item.value" [selected]="item.value" [disabled]="item.value === 'agent'">
              {{item.key}}
            </option>
          </select>
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="email">Email:<span class="required-asterisk">*</span> </label>
          <input [disabled]="disableField()" type="text" class="form-control" id="email" placeholder="Email"
            [(ngModel)]="email">
        </div>
      </div>

      <br>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-2 col-form-label" for="phone">Phone:<span class="required-asterisk">*</span> </label>
          <input type="text" class="form-control" id="phone" placeholder="Phone" [(ngModel)]="phone"
          (input)="specialCharNumberOnly($event)" (keypress)="specialCharNumberOnly($event)"
            (paste)="specialCharNumberOnly($event)">
        </div>
      </div>

      <br>

      <!-- <div class="row"> -->
      <!-- <div class="form-group detail-input-group"> -->
      <!-- <label class="col-md-2 col-form-label" for="totalAttempt">Total Attempt:<span class="required-asterisk">*</span> </label> -->
      <input type="hidden" class="form-control" id="totalAttempt" placeholder="totalAttempt" [(ngModel)]="totalAttempt">
      <!-- </div> -->
      <!-- </div> -->


      <div class="form-group row ">
        <div class="col-md-2">
          <label class="col-form-label">Avatar</label>
        </div>
        <div class="col-md-4 input-group " [hidden]="showImage">
          <input type="file" accept="image/*" id="customFile" (change)="handleInputChange($event)" *ngIf="!loaded">
          <label class="custom-file-label" for="customFile"></label>
        </div>
      </div>

      <div class="form-group row" [hidden]="!showImage">
        <div class="col-md-4 col-md-offset-2 input-group">
          <img id="profilePic" [src]="imageSrc" (load)="handleImageLoad()" [class.loaded]="imageLoaded" />
          <div class="input-group-append">
            <button id="close-btn" (click)="cancel()"><i class="fa fa-close"></i></button>
          </div>
        </div>
      </div>

      <br>
      <br>

      <div class="form-group row">
        <div class="col-md-2 col-md-offset-10 input-group">
          <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
            [fullScreen]="true" size="medium">
            <p class="loading">Updating ....</p>
          </ngx-spinner>
          <button type="button" class="btn btn-primary btn-lg" (click)="onSave()">Save</button>

        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->