import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  headers: HttpHeaders = new HttpHeaders();

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getApplicationList() {
    
    return this.http.get(this.baseUrl + '/application/shared')
      .pipe(map((response : any) => {
        return response;
      }))
  }


  downloadApplicationPostman(){
    return this.http.get(this.baseUrl + '/application/postman')
    .pipe(map((response : any) => {
      return response;
    }))
  }


  getApplicationById(id){
    return this.http.get(this.baseUrl + '/application/'+id)
    .pipe(map((response : any) => {
      return response;
    }))
  }

  regenerateKeys(id){
    return this.http.put(this.baseUrl + '/application/regenerate/'+id,null)
      .pipe(map((response: any) => {
          return response;
      }));
  }

}
