<div class="row" id="headerBar">
    <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Reports</h1>
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
    <nb-card-header>
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Export Verification Details </h1>
            </div>
          </div>
        </div>
      </div>
    </nb-card-header>

    <nb-card-body> 
        <div class="col-md-12">
            <div class="row"> 
                <div class="col-md-3 d-flex"> 
                    <label class="col-form-label label-width" for="fromDate">From Date: <span class="required-asterisk">*</span> </label>
                    <input type="date" class="form-control" id="fromDate" placeholder="dd-MM-yyyy" max="{{maxDate | date:'yyyy-MM-dd'}}" [(ngModel)]="fromDate" (keydown)="disableDate()" >
                </div>
 
                <div class="col-md-3 d-flex"> 
                  <label class="col-form-label label-width" for="toDate">To Date: <span class="required-asterisk">*</span> </label>
                  <input type="date" class="form-control" id="toDate" placeholder="dd-MM-yyyy" max="{{maxDate | date:'yyyy-MM-dd'}}" [(ngModel)]="toDate" (keydown)="disableDate()">
                </div>
 
                <div class="col-md-2">
                    <button type="button" (click)="onSubmit()" class="btn btn-info export" >
                       <i class="fas fa-cloud-download-alt" aria-hidden="true" title="Export .csv"></i>
                    </button> 
                </div> 
            </div>
        </div> 
    </nb-card-body>
</nb-card>