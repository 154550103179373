import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  headers: HttpHeaders = new HttpHeaders();

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getConfiguration() {
    return this.http.get(this.baseUrl + '/tenant/configurations/')
      .pipe(map((response : any) => {
        return response;
      }))
  }

  getConfigurationBySuperAdmin(tenantId) {
    return this.http.get(this.baseUrl + '/tenant/configurations/'+tenantId)
      .pipe(map((response : any) => {
        return response;
      }))
  }

  getAllMetadataByCurrentTenant(){
    return this.http.get(this.baseUrl + '/metadata/tenant/entry')
    .pipe(map((response : any) => {
      return response;
    }))
  }

  updateConfigurationBySuperAdmin(tenantId,config) {
    console.info('Config ::'+JSON.stringify(config));
    return this.http.put(this.baseUrl + '/tenant/configurations/'+tenantId,config)
      .pipe(map((response : any) => {
        return response;
      }))
  }

  updateMetaData(payload) {
    return this.http.put(this.baseUrl + '/metadata/tenant/entry',payload)
      .pipe(map((response : any) => {
        return response;
      }))
  }

  updateConfiguration(config) {
    return this.http.put(this.baseUrl + '/tenant/configurations/',config)
      .pipe(map((response : any) => {
        return response;
      }))
  }

}
