import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationListComponent } from './application-list/application-list.component';
import {NbToggleModule,NbCardModule} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AppRoutingModule } from '../app-routing.module';
import { NbIconModule} from '@nebular/theme';
import { NgxSpinnerModule } from 'ngx-spinner';
import {AlertModule} from '../shared/_alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import {ApplicationComponent} from './application.component';
import { SecretViewComponent } from './secret-view/secret-view.component';
import { FormsModule } from '@angular/forms';
import { SecretViewEncryptedComponent } from './secret-view-encrypted/secret-view-encrypted.component';
import { ButtonViewComponent } from './button-view/button-view.component';
import { ApplicationViewComponent } from './application-view/application-view.component';


@NgModule({
  declarations: [
    ApplicationComponent,
    ApplicationListComponent,
    SecretViewComponent,
    SecretViewEncryptedComponent,
    ButtonViewComponent,
    ApplicationViewComponent
  ],
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    AppRoutingModule,
    NbToggleModule,
    NbCardModule,
    BrowserModule,
    NbIconModule,
    AlertModule,
    NgbModule ,
    NgxSpinnerModule,
    FormsModule 
  ]
})
export class ApplicationModule { }
