import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration.component';
import { ConfigurationDetailComponent} from './configuration-detail/configuration-detail.component';
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from '../app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NbToggleModule,NbCardModule} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { NbIconModule, NbSelectModule} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CheckboxButtonComponent } from './checkbox-button/checkbox-button.component';




@NgModule({
  declarations: [ConfigurationComponent,ConfigurationDetailComponent, CheckboxButtonComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NbToggleModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgSelectModule,
    NbSelectModule,
    NbIconModule,
    Ng2SmartTableModule,
    NgbModule
  ]
})
export class ConfigurationModule { }
