import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from './login/login.component'
import { AppRoutingModule } from '../app-routing.module';
import { AuthComponent } from './auth.component';
import {AlertModule} from '../shared/_alert/alert.module';
import { ReactiveFormsModule ,FormsModule} from '@angular/forms';
import { NbSidebarModule, NbLayoutModule,} from '@nebular/theme';
import { NgxSpinnerModule } from 'ngx-spinner';




@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    AlertModule,
    AppRoutingModule,
    FormsModule,
    NbLayoutModule,
    NbSidebarModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ]
})
export class AuthModule { }


