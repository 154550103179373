

<div class="row" id="headerBar">
    <h1 id="pageTopic" > &nbsp;&nbsp;&nbsp;Upload Meta Data</h1>
   </div>
   
   <br>
 <br>
 <br>
 <br>
 <br>
   
   <nb-card>
     <nb-card-header>
       <div class="content-header">
         <div class="container-fluid">
           <div class="row mb-2">
             <div class="col-sm-6">
               <h1 class="m-0 text-dark">Upload File </h1>
             </div>
           </div>
         </div>
       </div>
   
     </nb-card-header>
     
           <nb-card-body>
            <div class="container">

             <form [formGroup]="form" (ngSubmit)="submitForm()" enctype="multipart/form-data">
              <div class="row form-group">               
                 <label class="col-md-2">CSV File: </label>
                  <input class="col-md-4" type="file" name="CSVfile" (change)="uploadFile($event)" >
              </div>
              <ngx-spinner bdColor = "rgb(51, 153, 255,0.1)" size = "medium" color = "#0073e6" type = "square-jelly-box" [fullScreen] = "true" size="medium">
                <p class="loading">Uploading File ....</p>
              </ngx-spinner>
      
              <div class="row form-group">
                  <button type="submit" class="offset-md-6 col-md-2 btn btn-primary btn-lg">Upload</button>
              </div>
          </form>
        </div>      
           </nb-card-body>
         </nb-card>    
       <!-- Content Wrapper. Contains page content -->
       