import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
import {UserService} from '../user.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../shared/_alert/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { UserTypeLabelComponent } from 'src/app/agent/user-type-label/user-type-label.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  public loading = false;
  private selectedRow: any;
  closeResult: string;
  pageOffset=0;
  dataLimit =15;
  disablePrevButton = true;
  disableNxtButton = false;
  dataSize = 0;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
};

  source: LocalDataSource = new LocalDataSource();
  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 15
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      firstName: {
        title: 'First Name',
        type: 'String',
        width: '20%',
        filter: false
      },
      lastName: {
        title: 'Last Name',
        type: 'String',
        width: '20%',
        filter: false
      },
      // id: {
      //   title: 'User ID',
      //   type: 'String',
      //   width: '50%',
      //   filter: false
      // },
      userType: {
        title: 'User Type',
        type: 'custom',
        renderComponent: UserTypeLabelComponent,
        width: '15%',
        class: 'text-align: center',
        filter: false
      },
      email: {
        title: 'Email',
        type: 'String',
        width: '25%',
        filter: false
      },
      phone: {
        title: 'Phone',
        type: 'String',
        width: '20%',
        filter: false
      }
    },
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal

  ) { 
  }
  
  onUserRowSelect(event): void {
   // this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  
  private loadUsers(pageOffset,limit) {
    this.spinner.show();
    this.disableNxtButton = true;
    this.userService.getUsers(pageOffset,limit)
      .subscribe(users => {
    this.dataSize=users.length;
        if(users.length>0){
          this.source.load(users);
          this.disableNxtButton = false;
          this.disablePrevButton=false;
        }else{
          this.disableNxtButton = true;
          this.disablePrevButton=false;
        }
        this.spinner.hide();
      },
        (error) => {
          console.log(error);
          this.spinner.hide();
        });
  }

  showStaticModal(rowData: any) {
    this.selectedRow = rowData;
    const modalRef = this.modalService.open(ConfirmationDialogComponent,{ size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = "Delete User";
    modalRef.componentInstance.message = "Are you sure you want to delete this user?";
    modalRef.componentInstance.btnOkText = "Ok";
    modalRef.componentInstance.btnCancelText = "Cancel";

    modalRef.result.then((result) => {
        if (result) {
          this.userService.deleteUser(this.selectedRow.data.id)
          .subscribe(
            result => {
              this.alertService.success("User has been deleted successfully", this.options)
              this.loadUsers(this.pageOffset,this.dataLimit);
            },
            error => {
              console.log(error);
              this.alertService.error("User deleted failed", this.options)
            }
          );
              }else{
                modalRef.close();
              }         
        });
  }


  onBack(){ 
    if(this.pageOffset>0){
      this.pageOffset=this.pageOffset-1;
      this.loadUsers(this.pageOffset,this.dataLimit);
    }else{
      this.disablePrevButton=true;
    }
  }

  onNext(){
    this.pageOffset=this.pageOffset+1;
    this.loadUsers(this.pageOffset,this.dataLimit);
  }
 

  ngOnInit(): void {
    this.tableSettings=Object.assign({},this.tableSettings); 
    this.loadUsers(this.pageOffset,this.dataLimit);
  }

  onEdit(event): void {
    this.router.navigate([event.data.id, 'edit'], { relativeTo: this.route });
  }

  onCreate(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

}
