import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { User } from '../model/user.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  headers = {
    headers: {
      'Content-Type':'application/json'
    }
  }

  baseUrl = environment.baseUrl;

  

  constructor(private http: HttpClient) {}

  getVerifyQueue(page,limit,status) {
    return this.http.get(this.baseUrl + '/verify/queue/'+status+'?page='+page+'&limit='+limit)
      .pipe(map((response : any) => {
        return response;
      }))
  }


}
