import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";



@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }


  changePassword(email, currentPassword, newPassword){
    let payload = {
      email:email,
      currentPassword: currentPassword,
      newPassword:newPassword
    }

    return this.http.post(this.baseUrl + '/user/resetpassword',
    payload).pipe(map((response: any) => {
        return response;
    }));

  }

  getUserDetail(){
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  }

  getTenantID(){
    const tenantID = sessionStorage.getItem('tenantID');
    return tenantID;
  }


}
