import { Injectable } from '@angular/core'; 
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  public exportAsExcelFile(json: any[], json2: any[], excelFileName: string): void {

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const myworksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    // const myworkbook: XLSX.WorkBook = { Sheets: { 'Report_Details': myworksheet, 'Total_Counts': myworksheet2 }, SheetNames: ['Report_Details', 'Total_Counts'] };
    const myworkbook: XLSX.WorkBook = { Sheets: { 'Report_Details': myworksheet}, SheetNames: ['Report_Details'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  public exportAsCSVFile(json: any[], excelFileName: string): void {

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const myworksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    // const myworkbook: XLSX.WorkBook = { Sheets: { 'Report_Details': myworksheet, 'Total_Counts': myworksheet2 }, SheetNames: ['Report_Details', 'Total_Counts'] };
    const myworkbook: XLSX.WorkBook = { Sheets: { 'Report_Details': myworksheet}, SheetNames: ['Report_Details'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'csv', type: 'array' });
    this.saveAsCSVFile(excelBuffer, excelFileName);
  }

  private saveAsCSVFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + CSV_EXTENSION);
  }
}
